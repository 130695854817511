import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import Orders from '../components/orders';

function OrdersPage() {
    const { t } = useTranslation();

    return (
        <div className="container">
            <Card className="card-header-wo-border" bordered={false} title={t('orders')}>
                <Orders />
            </Card>
        </div>
    );
}

export default OrdersPage;
