import { useEffect, useRef, useState } from 'react';
import {
    Button, Steps, Space, Grid, Typography, Col, Row, Card,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from '../hooks/useUser';

import '../styles/make-order.less';
import MakeOrderFirstStep from '../components/make-order-steps/first';
import MakeOrderMemberStep from '../components/make-order-steps/member';
import { useFetch } from '../hooks/useFetch';
import { useOrder } from '../hooks/useOrder';
import StripeOrderPayModal, { IOrderPayModalRef } from '../components/stripe/order-pay-modal';
import PaymentTypeSelectionButton, { PaymentTypes } from '../components/payment-type-selection-button';
import { useRobokassa } from '../hooks/robokassa/useRobokassa';
import { useOrderForm } from '../hooks/useOrderForm';
import OrderPromoCode from '../components/order-promo-code';
import { useFormWatchMode } from '../hooks/useFormWatchMode';
import OrderTermsOfServiceButton from '../components/order-terms-of-service-button';
import Collapse from '../components/collapse';
import OrderQrCodeModal, { IOrderQrCodeModalRef } from '../components/order-qr-code-modal';

const { useBreakpoint } = Grid;

function MakeOrderPage() {
    const { watchMode } = useFormWatchMode();
    const params = useParams();
    const { id } = params;

    const [step, setStep] = useState(0);
    const [shouldRecognizeStep, setShouldRecognizeStep] = useState(false);
    const { info } = useUser();
    const { sm, xl } = useBreakpoint();
    const cancelFetch = useFetch();
    const order = useOrder({ fetchData: true, orderId: id });
    const { t } = useTranslation();
    const stripePayModal = useRef<IOrderPayModalRef>();
    const navigate = useNavigate();
    const robokassa = useRobokassa();
    const { availableFormItems } = useOrderForm();
    const qrCodeModal = useRef<IOrderQrCodeModalRef>();

    const type = order.data?.type || params.type || '';

    const getAvailableSteps = () => {
        let availableSteps = [0, 1, 2, 3];

        if (availableFormItems.order_purchases?.types.includes(type)) {
            availableSteps = [0, 1, 2, 3];
        }

        if (availableFormItems.order_package?.types.includes(type) && !order.data?.shouldSelectDeliveryType) {
            availableSteps = [0, 1, 2, 3];
        }

        return availableSteps;
    };

    const getCurrentStepIndex = () => getAvailableSteps().findIndex((el) => el === step);

    const getOrderStep = () => {
        if (availableFormItems.order_package?.types.includes(type) && (order.data?.package === null || order.data?.package?.items.length === 0)) {
            return 0;
        }
        if (availableFormItems.order_purchases?.types.includes(type) && order.data?.purchases?.length === 0) {
            return 0;
        }
        if (availableFormItems.sender?.types.includes(type) && order.data?.sender === null) {
            return 1;
        }
        if (order.data?.recipient === null) {
            return 2;
        }
        return 3;
    };

    const getNextStep = (previous = false) => {
        const steps = getAvailableSteps();
        const index = steps.findIndex((el) => el === step);
        return steps[previous ? index - 1 : index + 1];
    };

    useEffect(() => () => {
        order.clear();
    }, []);

    useEffect(() => {
        if (typeof id === 'undefined') {
            setStep(0);
        } else {
            setShouldRecognizeStep(true);
        }
        order.update(id);
    }, [id]);

    useEffect(() => {
        if (order.data && shouldRecognizeStep && Object.keys(availableFormItems).length) {
            setStep(getOrderStep());
            setShouldRecognizeStep(false);
        }
    }, [order.data, shouldRecognizeStep, availableFormItems]);

    if (!info) {
        return null;
    }

    const goNextStep = () => setStep(getNextStep());

    const onBackPress = () => setStep(getNextStep(true));

    const onCancel = async () => {
        const result = await cancelFetch.request({
            url: `/orders/${order.data?.id}/delete`,
        });
        if (result.success) {
            order.clear();
            setStep(0);
        }
    };

    const onPaymentTypeSelected = async (paymentType: PaymentTypes) => {
        if (order.data) {
            if (paymentType === 'robokassa') {
                const rResult = await robokassa.getPaymentData(order.data);
                if (rResult?.url) {
                    window.location.href = rResult.url;
                }
            }
            if (paymentType === 'stripe') {
                stripePayModal.current?.show(order.data);
            }
        }
    };

    const onPaySucceed = () => navigate('/orders');

    const onGetQrCodeClick = () => (order.data ? qrCodeModal.current?.show(order.data) : undefined);

    return (
        <div className="container">
            <Card title={availableFormItems.order_package?.types.includes(type) ? t('sendingParcelToRussia') : t('shoppingFromStores')}>
                <Space direction="vertical" size="large" className="w-100">

                    <Steps className="make-order-steps" current={getCurrentStepIndex()} direction={xl ? 'horizontal' : 'vertical'}>
                        <Steps.Step title={t('orderFromTo')} />
                        {/* {availableFormItems.order_package?.types.includes(type) && (
                            <Steps.Step title={t('orderTransportation')} />
                        )} */}
                        {/* <Steps.Step title={t('orderFiller')} /> */}
                        {availableFormItems.sender?.types.includes(type) && (
                            <Steps.Step title={t('orderSender')} />
                        )}
                        <Steps.Step title={t('orderRecipient')} />
                        {/* {order.data?.shouldSelectDeliveryType && (
                            <Steps.Step title={t('deliveryType')} />
                        )} */}
                        <Steps.Step title={t('orderConfirmation')} />
                    </Steps>

                    <fieldset className="order-form-container" disabled={watchMode}>

                        {step === 0 && (
                            <MakeOrderFirstStep
                                canMoveBetweenSteps
                                onBackPress={onBackPress}
                                goNextStep={goNextStep}
                            />
                        )}

                        {step === 1 && (
                            <MakeOrderMemberStep
                                canMoveBetweenSteps
                                type="SENDER"
                                onBackPress={onBackPress}
                                goNextStep={goNextStep}
                            />
                        )}

                        {step === 2 && (
                            <MakeOrderMemberStep
                                canMoveBetweenSteps
                                type="RECIPIENT"
                                onBackPress={onBackPress}
                                goNextStep={goNextStep}
                            />
                        )}

                    </fieldset>

                    {step === 3 && (
                        <Row gutter={[30, 30]}>
                            <Col xs={24}>
                                <Collapse title={t('orderFromTo')}>
                                    <MakeOrderFirstStep onBackPress={onBackPress} goNextStep={goNextStep} />
                                </Collapse>
                            </Col>
                            {/* <Col xs={24}>
                                {availableFormItems.order_package?.types.includes(type) && (
                                    <Collapse title={t('orderTransportation')}>
                                        <MakeOrderSecondStep onBackPress={onBackPress} goNextStep={goNextStep} />
                                    </Collapse>
                                )}
                            </Col> */}
                            {/* <Col xs={24}>
                                <Collapse title={t('orderFiller')}>
                                    <MakeOrderThirdStep onBackPress={onBackPress} goNextStep={goNextStep} />
                                </Collapse>
                            </Col> */}
                            <Col xs={24}>
                                {availableFormItems.sender?.types.includes(type) && (
                                    <Collapse title={t('orderSender')}>
                                        <MakeOrderMemberStep type="SENDER" onBackPress={onBackPress} goNextStep={goNextStep} />
                                    </Collapse>
                                )}
                            </Col>
                            <Col xs={24}>
                                <Collapse title={t('orderRecipient')}>
                                    <MakeOrderMemberStep type="RECIPIENT" onBackPress={onBackPress} goNextStep={goNextStep} />
                                </Collapse>
                            </Col>
                            {/* {order.data?.shouldSelectDeliveryType && (
                                <Col xs={24}>
                                    <Collapse title={t('deliveryType')}>
                                        <MakeOrderDeliveryStep onBackPress={onBackPress} goNextStep={goNextStep} />
                                    </Collapse>
                                </Col>
                            )} */}
                            <Col xs={24}>
                                {order.data && (
                                    <Collapse title={t('promoCode')}>
                                        <OrderPromoCode order={order.data} onSucceed={order.update} />
                                    </Collapse>
                                )}
                            </Col>
                            <Col xs={24}>
                                {order.data && !order.data?.paid && (
                                    <Typography.Title level={4}>
                                        {`${t('amountToPay')}: ${order.data.amount_to_pay_label}`}
                                        {' '}
                                        {typeof order.data.promocode?.code === 'string' && `(${t('discount')}: ${order.data.promocode?.amount_of_discount}%)`}
                                        <br />
                                        {typeof order.data.delivery_time === 'string' && `${t('deliveryTime')}: ${order.data.delivery_time}`}
                                    </Typography.Title>
                                )}
                            </Col>
                            <Col className="text-center" xs={24}>
                                <Space direction={!sm ? 'vertical' : 'horizontal'} hidden={watchMode && order.data?.paid} size={!sm ? 'small' : 'large'}>
                                    <div className="form-action-buttons" hidden>
                                        <Button type="link" onClick={onCancel} loading={cancelFetch.loading}>
                                            {t('cancel')}
                                        </Button>
                                    </div>
                                    <Space direction="vertical">
                                        {order.data && !order.data.paid && (
                                            <OrderTermsOfServiceButton
                                                onTermsAccepted={order.update}
                                                order={order.data}
                                            />
                                        )}
                                        {!order.data?.paid && (
                                            <PaymentTypeSelectionButton
                                                disabled={typeof order.data?.amount_to_pay !== 'number' || order.data.amount_to_pay <= 0}
                                                onSelect={onPaymentTypeSelected}
                                                text={t('pay') as string}
                                                icon={null}
                                                order={order.data}
                                            />
                                        )}
                                    </Space>
                                    {order.data?.paid && order.data?.qr_code_available && !!order.data?.terms_of_service_accepted && (
                                        <Button
                                            type="link"
                                            title={t('getQrCode') as string}
                                            onClick={onGetQrCodeClick}
                                        >
                                            {t('getQrCode')}
                                        </Button>
                                    )}
                                </Space>
                            </Col>
                        </Row>
                    )}

                </Space>
            </Card>
            <StripeOrderPayModal ref={stripePayModal} onPaySucceed={onPaySucceed} />
            <OrderQrCodeModal ref={qrCodeModal} />
        </div>
    );
}

export default MakeOrderPage;
