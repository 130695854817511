import { useEffect } from 'react';
import {
    Avatar, Card, Row, Col, Space, Typography, Upload, Button, Form, Input, Modal, Grid, Divider,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { useTranslation } from 'react-i18next';
import { useUser } from '../hooks/useUser';
import { useApp } from '../hooks/useApp';
import { useUserAddress } from '../hooks/useUserAddress';
import { getRequiredFieldMessage } from '../helpers/form';
import HomeTransactionsPanel from '../components/home-panels/transactions';
import { useForm } from '../hooks/useForm';
import { useUserEmailConfirm } from '../hooks/useUserEmailConfirm';
import PhoneInput from '../components/phone-input';
import ExitIcon from '../assets/icons/exit';

const { confirm } = Modal;
const { useBreakpoint } = Grid;

function HomePage() {
    const app = useApp();
    const { info, update, uploadPhoto } = useUser();
    const { requestCode } = useUserEmailConfirm();
    const navigate = useNavigate();
    const userAddress = useUserAddress();
    const { t } = useTranslation();
    const [form] = useForm();
    const { md, lg } = useBreakpoint();

    useEffect(() => {
        if (typeof window.localStorage.getItem('create-order') === 'string') {
            window.localStorage.removeItem('create-order');
            navigate('/orders'); // TODO Кидать на форму заказа
        }
    }, []);

    const onDeleteClick = () => {
        confirm({
            title: t('userDeleteTitle'),
            icon: <ExclamationCircleOutlined />,
            content: t('userDeleteContent'),
            cancelText: t('cancel'),
            okText: t('delete'),
            onOk: onLogout,
        });
    };

    const onFinish = (values: any) => {
        update.request(values);
    };

    const onLogout = app.logout;

    const onUploadChange = async (e: UploadChangeParam<UploadFile<any>>) => {
        if (e.event?.percent === 100) {
            uploadPhoto.request(e.file.originFileObj as Blob);
        }
    };

    const column1 = (
        <Row gutter={lg ? [0, 30] : [0, 20]}>
            <Col xs={24}>
                <Card bordered={false}>
                    <Space align="center" className="w-100" direction={!lg ? 'vertical' : 'horizontal'} size="large">
                        <Avatar
                            src={info?.photo || 'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png'}
                            size={120}
                        />
                        <Space direction="vertical">
                            <Typography.Title level={3} className="mb-0">
                                {info?.name}
                                {' '}
                                {info?.surname}
                            </Typography.Title>
                            <Upload className="user-avatar-upload" showUploadList={false} onChange={onUploadChange}>
                                <Button className="mw-240" type="primary" loading={uploadPhoto.loading}>
                                    {t('uploadPhoto')}
                                </Button>
                            </Upload>
                        </Space>
                    </Space>
                    <Divider type="horizontal" />
                    <Space align="center" className="w-100" direction={!lg ? 'vertical' : 'horizontal'}>
                        <Button className="mw-240" danger type="default" onClick={onDeleteClick}>
                            {t('delete')}
                        </Button>
                        <Button type="text" icon={<ExitIcon width={16} height={16} style={{ marginTop: -2 }} />} onClick={onLogout}>
                            {t('logout')}
                        </Button>
                    </Space>
                </Card>
            </Col>
            <Col xs={24}>
                <HomeTransactionsPanel />
            </Col>
        </Row>
    );

    const column2 = (
        <Row gutter={lg ? [0, 30] : [0, 20]}>
            <Col xs={24}>
                <Card bordered={false} title={t('userSettings')}>
                    {info && (
                        <Form initialValues={info} layout="vertical" onFinish={onFinish}>
                            <Row gutter={[30, 30]}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        label={t('name')}
                                        name="name"
                                        rules={[
                                            { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        label={t('surname')}
                                        name="surname"
                                        rules={[
                                            { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label={t('phone')} name="phone">
                                        <PhoneInput />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        label={t('email')}
                                        name="email"
                                        validateStatus={info.email_verified_at === null ? 'warning' : undefined}
                                        extra={info.email_verified_at === null ? (
                                            <div className="d-flex">
                                                <div className="ant-form-item-explain ant-form-item-explain-connected" role="alert">
                                                    <div className="ant-form-item-explain-error">
                                                        {t('emailNotConfirmed')}
                                                    </div>
                                                </div>
                                                <Button
                                                    className="email-confirm-btn"
                                                    type="link"
                                                    loading={requestCode.loading}
                                                    onClick={requestCode.request}
                                                >
                                                    {t('confirm')}
                                                </Button>
                                            </div>
                                        ) : undefined}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Button className="mw-240" type="primary" htmlType="submit" loading={update.loading}>
                                        {t('save')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Card>
            </Col>
            <Col xs={24}>
                <Card bordered={false} title={t('addressSettings')} loading={userAddress.loading}>
                    <Form form={form} initialValues={userAddress.data} layout="vertical" onFinish={userAddress.update}>
                        <Row gutter={[30, 30]}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('country')}
                                    name="country"
                                    rules={[
                                        { required: true, message: getRequiredFieldMessage(t('country')) },
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('region')}
                                    name="region"
                                    rules={[
                                        { required: true, message: getRequiredFieldMessage(t('region')) },
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('city')}
                                    name="city"
                                    rules={[
                                        { required: true, message: getRequiredFieldMessage(t('city')) },
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('district')}
                                    name="district"
                                    rules={[
                                        { required: true, message: getRequiredFieldMessage(t('district')) },
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('street')}
                                    name="street"
                                    rules={[
                                        { required: true, message: getRequiredFieldMessage(t('street')) },
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('houseNumber')}
                                    name="house"
                                    rules={[
                                        { required: true, message: getRequiredFieldMessage(t('houseNumber')) },
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('entrance')}
                                    name="entrance"
                                    rules={[
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('apartmentNumber')}
                                    name="apartment"
                                    rules={[
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Button className="mw-240" type="primary" htmlType="submit" loading={userAddress.updating || userAddress.loading}>
                                    {t('save')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>
        </Row>
    );

    return (
        <Row className="container" gutter={lg ? [30, 30] : [!md ? 0 : 20, 20]}>
            <Col xs={24} xl={0}>
                {column1}
            </Col>
            <Col xs={24} xl={14}>
                {column2}
            </Col>
            <Col xs={0} xl={10}>
                {column1}
            </Col>
        </Row>
    );
}

export default HomePage;
