import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetch } from './useFetch';
import { IWarehouse } from '../types/warehouse';
import { IListResponse } from '../types/list';

export const useMyAddresses = () => {
    const fetch = useFetch();
    const [data, setData] = useState<IListResponse<IWarehouse>>();
    const [searchParams] = useSearchParams();
    const { i18n } = useTranslation();
    const page = searchParams.get('page');

    const getData = async () => {
        const result = await fetch.request({
            url: `/warehouses?page=${page || 1}`,
            method: 'post',
            data: {
                count: 10,
                sorters: [{ column: 'id', desc: true }],
            },
        });
        if (result.success && result.data) {
            setData(result.data);
        }
    };

    useEffect(() => {
        getData();
    }, [page, i18n.language]);

    return {
        data,
        loading: fetch.loading,
    };
};
