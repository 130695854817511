import { useState, useEffect } from 'react';
import { IOrder } from '../../types/order';
import { useFetch } from '../useFetch';

export const useStripeOrderPay = (order?: IOrder) => {
    const csFetch = useFetch();
    const [data, setData] = useState<{clientSecret?: string; paid?: boolean}>();

    const getClientSecret = async () => {
        if (order) {
            const result = await csFetch.request<{client_secret?: string, paid?: boolean;}>({
                url: `/orders/${order.id}/pay`,
                data: {
                    type: 'stripe',
                },
                method: 'post',
            });
            setData({ clientSecret: result.data?.client_secret, paid: result.data?.paid });
        } else {
            setData(undefined);
        }
    };

    useEffect(() => {
        getClientSecret();
    }, [order?.id]);

    return {
        clientSecret: data?.clientSecret,
        paid: data?.paid,
        loading: csFetch.loading,
    };
};
