import { useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Button, Col, Divider, Grid, Modal, Row, Space,
} from 'antd';
import moment from 'moment';
import { EyeFilled, QrcodeOutlined } from '@ant-design/icons';
import { useFetch } from '../hooks/useFetch';
import { IOrder } from '../types/order';
import { IListResponse } from '../types/list';
import StripeOrderPayModal, { IOrderPayModalRef } from './stripe/order-pay-modal';
import OrderQrCodeModal, { IOrderQrCodeModalRef } from './order-qr-code-modal';
import { useRobokassa } from '../hooks/robokassa/useRobokassa';
import { useTableLayoutProcessor } from '../hooks/useTableLayoutProcessor';
import { useOrdersPusher } from '../hooks/pusher/useOrdersPusher';
import PaymentTypeSelectionButton, { PaymentTypes } from './payment-type-selection-button';
import Table from './table';
import OrderTermsOfServiceButton from './order-terms-of-service-button';
import EditIcon from '../assets/icons/edit';
import TrashIcon from '../assets/icons/trash';

function Orders() {
    const deleteFetch = useFetch();
    const listFetch = useFetch();
    const [data, setData] = useState<IListResponse<IOrder>>();
    const stripePayModal = useRef<IOrderPayModalRef>();
    const qrCodeModal = useRef<IOrderQrCodeModalRef>();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');
    const { i18n, t } = useTranslation();
    const robokassa = useRobokassa();
    const { xxl } = Grid.useBreakpoint();
    useTableLayoutProcessor();

    const getList = async () => {
        let url = '/orders';

        if (typeof page === 'string') {
            url += `?page=${page}`;
        }

        const result = await listFetch.request<IListResponse<IOrder>>({
            url,
            method: 'post',
            data: {
                count: 30,
                sorters: [{ column: 'id', desc: true }],
            },
        });
        if (result.success && result.data) {
            setData(result.data);
        }
    };

    useOrdersPusher({ onOrdersUpdated: getList });

    const onDeleteClick = (id: number) => {
        Modal.confirm({
            title: t('confirmAction'),
            content: t('deleteConfirmation'),
            onOk: () => deleteFetch.request({
                url: `/orders/${id}/delete`,
            }).then(() => getList()),
            okText: t('delete'),
        });
    };

    const onGetQrCodeClick = (row: IOrder) => qrCodeModal.current?.show(row);

    const onPaymentTypeSelected = async (type: PaymentTypes, row: IOrder) => {
        if (type === 'robokassa') {
            const rResult = await robokassa.getPaymentData(row);
            if (rResult?.url) {
                window.location.href = rResult.url;
            }
        }
        if (type === 'stripe') {
            stripePayModal.current?.show(row);
        }
    };

    useEffect(() => {
        getList();
    }, [page, i18n.language]);

    const expandedRowRender = (record: IOrder) => (
        <Space className="w-100" direction="vertical">
            <Space size="middle">
                <span>Статус:</span>
                <span className="order-status-span" style={{ backgroundColor: record.status.color }}>{record.status.text}</span>
            </Space>
            <Divider className="w-100" />
            <Row gutter={[20, 20]}>
                <Col xs={24} sm={12} md={12} lg={8} xl={4}>
                    <span>{`${t('deliveryDate')}:`}</span>
                    <br />
                    <span>{record.delivery_date}</span>
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={4}>
                    <span>{`${t('paid')}:`}</span>
                    <br />
                    <span>{record.paid ? t('yes') : t('no')}</span>
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={4}>
                    <span>{`${t('amountToPay')}:`}</span>
                    <br />
                    <span>{record.amount_to_pay_label}</span>
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={4}>
                    <span>{`${t('paidAmount')}:`}</span>
                    <br />
                    <span>{record.paid_amount_label}</span>
                </Col>
            </Row>
        </Space>
    );

    return (
        <>
            <StripeOrderPayModal ref={stripePayModal} onPaySucceed={getList} />
            <OrderQrCodeModal ref={qrCodeModal} />
            <Table
                showHeader
                scroll={{ x: true }}
                rowKey="id"
                expandable={{
                    expandedRowKeys: data?.list.map((el) => el.id),
                    expandedRowRender,
                }}
                loading={listFetch.loading}
                pagination={{
                    current: data?.currentPage,
                    total: data?.total,
                    pageSize: 30,
                }}
                dataSource={data?.list}
                columns={[
                    {
                        dataIndex: 'id',
                        title: '№',
                        width: 100,
                        render: (val: number) => <b>{val}</b>,
                    },
                    {
                        dataIndex: 'type',
                        title: t('orderType'),
                    },
                    {
                        dataIndex: 'deliveryTypeName',
                        title: t('deliveryType'),
                    },
                    {
                        dataIndex: 'tracking',
                        title: t('trackingNumber'),
                    },
                    {
                        dataIndex: 'created_at',
                        title: t('orderCreatedDate'),
                        render: (_, row: IOrder) => moment(row.created_at).format('DD.MM.YYYY').toString(),
                    },
                    {
                        dataIndex: 'from_to',
                        title: t('direction'),
                    },
                    {
                        dataIndex: 'sender.name',
                        title: t('fromWhom'),
                    },
                    {
                        dataIndex: 'recipient.name',
                        title: t('toWhom'),
                    },
                    {
                        className: 'table-actions-column',
                        fixed: xxl ? false : 'right',
                        title: '',
                        key: 'actions',
                        render: (_, row: IOrder) => (
                            <Space align="baseline" direction="vertical" size="small">
                                {row.orderingDone && !row.terms_of_service_accepted && (
                                    <OrderTermsOfServiceButton
                                        onTermsAccepted={getList}
                                        order={row}
                                        tableAction
                                    />
                                )}
                                {row.paid && (
                                    <Link to={`/order/${row.id}?mode=watch`}>
                                        <Button
                                            type="link"
                                            icon={<EyeFilled />}
                                        />
                                    </Link>
                                )}
                                {row.qr_code_available && (
                                    <Button
                                        type="link"
                                        icon={<QrcodeOutlined />}
                                        title={t('getQrCode') as string}
                                        onClick={() => onGetQrCodeClick(row)}
                                    />
                                )}
                                {(!row.orderingDone || !row.paid) && (
                                    <Link to={`/order/${row.id}`}>
                                        <Button
                                            type="link"
                                            icon={<EditIcon />}
                                            title={t('editOrder') as string}
                                        />
                                    </Link>
                                )}
                                {row.orderingDone && !row.paid && !!row.terms_of_service_accepted && (
                                    <PaymentTypeSelectionButton
                                        order={row}
                                        onSelect={(type) => onPaymentTypeSelected(type, row)}
                                        onTable
                                    />
                                )}
                                {row.canBeDeleted && (
                                    <Button
                                        type="link"
                                        icon={<TrashIcon />}
                                        onClick={() => onDeleteClick(row.id)}
                                    />
                                )}
                            </Space>
                        ),
                    },
                ]}
            />
        </>
    );
}

export default Orders;
