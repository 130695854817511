import { Collapse as ACollapse, CardProps } from 'antd';

function Collapse(props: CardProps) {
    const { children, title } = props;

    return (
        <ACollapse>
            <ACollapse.Panel key={1} header={title}>
                {children}
            </ACollapse.Panel>
        </ACollapse>
    );
}

export default Collapse;
