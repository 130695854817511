import { DefaultOptionType } from 'antd/lib/select';
import { useState } from 'react';
import { Key } from 'antd/lib/table/interface';
import { useFetch } from './useFetch';

export const useDeliveryTypes = (orderId?: number) => {
    const fetch = useFetch();
    const [data, setData] = useState<DefaultOptionType[]>([]);

    const getData = async () => {
        const result = await fetch.request({
            url: `/delivery/types/select${
                typeof orderId === 'number' ? `?orderId=${orderId}` : ''
            }`,
        });
        if (result.success) {
            setData(result.data);
        }
    };

    const getDataByPackage = async (params: {
        weight: number;
        width: number;
        length: number;
        height: number;
        insurance: boolean;
    }) => {
        const result = await fetch.request({
            url: '/delivery/types/select/package',
            method: 'post',
            data: params,
        });
        if (result.success) {
            setData(result.data);
        }
    };

    const getDataByPurchases = async (values: { purchases: Key[]; insurance: boolean; }) => {
        if (values.purchases.length > 0) {
            const result = await fetch.request({
                url: '/delivery/types/select/purchases',
                method: 'post',
                data: values,
            });
            if (result.success) {
                setData(result.data);
            }
        } else {
            setData([]);
        }
    };

    return {
        data,
        getData,
        getDataByPackage,
        getDataByPurchases,
        loading: fetch.loading,
    };
};
