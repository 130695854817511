import { notification } from 'antd';
import { IOrder } from '../types/order';
import { useFetch } from './useFetch';

export const useOrderTerms = (order?: IOrder) => {
    const fetch = useFetch();

    const acceptTermsOfService = async () => {
        if (order instanceof Object) {
            const response = await fetch.request({
                url: `/orders/${order.id}/accept-terms-of-service`,
                method: 'get',
            });
            if (typeof response.message === 'string') {
                notification.success({
                    message: (
                        <span dangerouslySetInnerHTML={{ __html: response.message }} />
                    ),
                });
            }
            return response.success;
        }
        return false;
    };

    return {
        acceptTermsOfService,
        loading: fetch.loading,
    };
};
