import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import Table from '../../components/table';
import { useMyAddresses } from '../../hooks/useMyAddresses';
import { IWarehouse } from '../../types/warehouse';
import { useTableLayoutProcessor } from '../../hooks/useTableLayoutProcessor';

function MyAddressesPage() {
    const { data, loading } = useMyAddresses();
    const { t } = useTranslation();
    useTableLayoutProcessor();

    // const onCopyClick = (value: any) => {
    //     navigator.clipboard.writeText(value);
    // };

    // const renderCell = (value: any) => (
    //     <Space>
    //         <Button icon={<CopyOutlined />} size="small" onClick={() => onCopyClick(value)} />
    //         {value}
    //     </Space>
    // );

    const expandedRowRender = (record: IWarehouse) => (
        <span style={{ whiteSpace: 'break-spaces' }}>
            {`${t('workMode')}:`}
            <br />
            {record.work_mode}
        </span>
    );

    return (
        <div className="container">
            <Card className="card-header-wo-border" bordered={false} title={t('warehouseAddresses')}>
                <Table
                    rowKey="id"
                    expandable={{
                        fixed: true,
                        expandedRowKeys: data?.list.map((el) => el.id),
                        expandedRowRender,
                    }}
                    loading={loading}
                    dataSource={data?.list}
                    pagination={{
                        current: data?.currentPage,
                        total: data?.total,
                        pageSize: data?.perPage,
                    }}
                    scroll={{
                        x: true,
                    }}
                    columns={[{
                        dataIndex: 'full_name',
                        title: t('name'),
                        width: 190,
                    }, {
                        dataIndex: 'company_name',
                        title: t('companyName'),
                    }, {
                        dataIndex: 'country',
                        title: t('country'),
                    }, {
                        dataIndex: 'city',
                        title: t('city'),
                    }, {
                        dataIndex: 'address',
                        title: t('address'),
                    }, {
                        dataIndex: 'postal_code',
                        title: t('postalCode'),
                    }, {
                        dataIndex: 'phone',
                        title: t('phone'),
                    }]}
                />
            </Card>
        </div>
    );
}

export default MyAddressesPage;
