function EditIcon() {
    return (
        <span className="anticon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_1_6528)">
                    <path d="M2.81204 15.4664L1.54537 20.9331C1.50167 21.1329 1.50317 21.34 1.54975 21.5392C1.59634 21.7384 1.68683 21.9246 1.81461 22.0844C1.9424 22.2441 2.10425 22.3733 2.28835 22.4625C2.47245 22.5516 2.67415 22.5985 2.8787 22.5998C2.97402 22.6094 3.07006 22.6094 3.16537 22.5998L8.66537 21.3331L19.2254 10.8131L13.332 4.93311L2.81204 15.4664Z" fill="#DB8912" />
                    <path d="M22.5471 5.54673L18.6138 1.61339C18.3552 1.3561 18.0053 1.21167 17.6405 1.21167C17.2757 1.21167 16.9257 1.3561 16.6671 1.61339L14.4805 3.80006L20.3671 9.68673L22.5538 7.50006C22.6818 7.37145 22.7831 7.21886 22.852 7.05104C22.921 6.88321 22.9561 6.70344 22.9555 6.52201C22.9549 6.34058 22.9185 6.16106 22.8484 5.99371C22.7784 5.82636 22.676 5.67447 22.5471 5.54673Z" fill="#DB8912" />
                </g>
            </svg>
        </span>
    );
}

export default EditIcon;
