import { Button, Card, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

function OrderCreatedPage() {
    const searchParams = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goToList = () => navigate('/orders');

    return (
        <Card>
            <Result
                status="success"
                title={t('orderPlacedSuccessfully')}
                subTitle={`${t('yourOrderNumber')} ${searchParams[0].get('orderId')}`}
                extra={[
                    <Button type="primary" onClick={goToList} size="large">
                        {t('goToOrdersList')}
                    </Button>,
                ]}
            />
        </Card>
    );
}

export default OrderCreatedPage;
