import {
    Button, Col, Modal, Row, Space, Spin,
} from 'antd';
import {
    forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PurchaseItemForm from './purchase-item-form';
import { IPurchase, IPurchaseItem } from '../types/purchase';
import { usePurchaseItem } from '../hooks/usePurchaseItem';

export interface IPurchaseItemFormModalInstance {
    open: (purchase: IPurchase, item?: IPurchaseItem) => void;
}

interface IPurchaseItemFormModalProps {
    onHide?: () => void;
}

const PurchaseItemFormModal = forwardRef(({ onHide }: IPurchaseItemFormModalProps, ref) => {
    const [purchase, setPurchase] = useState<IPurchase>();
    const [purchaseItem, setPurchaseItem] = useState<IPurchaseItem>();
    const {
        form, formData, formDataLoading, save, saveLoading,
    } = usePurchaseItem(true, purchase, purchaseItem);
    const { t } = useTranslation();

    const open = (p: IPurchase, pI?: IPurchaseItem) => {
        setPurchase(p);
        setPurchaseItem(pI);
    };

    useEffect(() => {
        if (typeof purchase === 'undefined') {
            form.resetFields();
            setPurchaseItem(undefined);
        }
    }, [purchase]);

    useImperativeHandle(ref, (): IPurchaseItemFormModalInstance => ({
        open,
    }));

    const hide = () => {
        setPurchase(undefined);
        if (typeof onHide === 'function') {
            onHide();
        }
    };

    const onFinish = (values: any) => save(values, hide);

    return (
        <Modal
            closable={false}
            open={purchase instanceof Object}
            footer={null}
            onCancel={hide}
            title={purchaseItem instanceof Object ? t('goods') : t('productAdding')}
        >
            <Row gutter={[30, 30]}>
                <Col xs={24}>
                    <span>{t('parcelDimensionsAttentionInformation')}</span>
                </Col>
                <Col xs={24}>
                    <Spin spinning={formDataLoading}>
                        <PurchaseItemForm
                            form={form}
                            onFinish={onFinish}
                            initialValues={formData}
                        />
                    </Spin>
                </Col>
                <Col className="text-right" xs={24}>
                    <Space className="form-action-buttons" size="large">
                        <Button type="link" onClick={hide}>
                            {t('cancel')}
                        </Button>
                        <Button type="primary" onClick={form.submit} loading={formDataLoading || saveLoading}>
                            {t('save')}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Modal>
    );
});

export default PurchaseItemFormModal;
