import { DollarCircleOutlined } from '@ant-design/icons';
import {
    Button, Modal, notification, Space,
} from 'antd';
import { useEffect, useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSize } from 'antd/lib/button/index';
import RobokassaLogo from '../assets/images/robokassa-logo.png';
import StripeLogo from '../assets/images/stripe-logo.png';
import { IOrder } from '../types/order';
import { useFetch } from '../hooks/useFetch';

export type PaymentTypes = 'robokassa' | 'stripe';

interface IOrderPayButtonProps {
    disabled?: boolean;
    size?: ButtonSize;
    onSelect: (value: PaymentTypes) => void;
    text?: string;
    icon?: ReactNode;
    order?: IOrder;
    onTable?: boolean;
}

function PaymentTypeSelectionButton({
    disabled, size, text, onSelect, icon, order, onTable,
}: IOrderPayButtonProps) {
    const [selected, setSelected] = useState<PaymentTypes>();
    const [modal, setModal] = useState(false);
    const { t } = useTranslation();
    const orderFetch = useFetch();

    const checkOrderAmountToPay = async () => {
        if (order && typeof order.amount_to_pay === 'number') {
            const result = await orderFetch.request<IOrder>({
                url: `/orders/${order.id}`,
            });

            if (result.success && result.data) {
                if (order.amount_to_pay !== result.data.amount_to_pay) {
                    notification.warning({ message: t('amountToPayChanged') });
                }
            }
        }
    };

    const hideModal = () => setModal(false);

    const onClick = () => setModal(true);

    useEffect(() => {
        if (typeof onSelect === 'function' && typeof selected === 'string') {
            onSelect(selected);
        }
        hideModal();
    }, [selected]);

    useEffect(() => {
        if (!modal) {
            setSelected(undefined);
        } else {
            checkOrderAmountToPay();
        }
    }, [modal]);

    return (
        <>
            {!!order?.terms_of_service_accepted && (
                <Button
                    disabled={disabled}
                    icon={icon}
                    onClick={onClick}
                    title={t('payOrder') as string}
                    type={onTable ? 'link' : 'primary'}
                    size={size}
                >
                    {text}
                </Button>
            )}
            <Modal closable={false} open={modal} title={t('paymentTypeSelection')} footer={null} onCancel={hideModal}>
                <div className="text-center">
                    <Space direction="horizontal">
                        <Button className="payment-type-btn" onClick={() => setSelected('robokassa')}>
                            <img
                                src={RobokassaLogo}
                                alt="Robokassa"
                                style={{ width: '100%' }}
                            />
                        </Button>
                        <Button className="payment-type-btn" onClick={() => setSelected('stripe')}>
                            <img
                                src={StripeLogo}
                                alt="Stripe"
                                style={{ width: '100%' }}
                            />
                        </Button>
                    </Space>
                </div>
            </Modal>
        </>
    );
}

PaymentTypeSelectionButton.defaultProps = {
    disabled: false,
    size: undefined,
    text: undefined,
    icon: <DollarCircleOutlined />,
    order: undefined,
};

export default PaymentTypeSelectionButton;
