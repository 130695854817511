/**
 * Парсинг сообщения сокета
 * @param message - сообщение сокета
 */
export const parseWebSocketMessage = <T>(message: any): T|null => {
    try {
        return JSON.parse(message);
    } catch (err) {
        return null;
    }
};
