import { useState } from 'react';
import {
    Button, Col, Form, Input, Row, Space,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthorizationContent from '../components/authorization-content';
import { getEnteredIncorrectlyFieldMessage, getRequiredFieldMessage } from '../helpers/form';
import { useFetch } from '../hooks/useFetch';
import { useApp } from '../hooks/useApp';
import { useForm } from '../hooks/useForm';
import PhoneInput from '../components/phone-input';
import FormItemWrapper from '../containers/form-item-wrapper';

interface ILoginResponse {
    token: string;
}

function LoginPage() {
    const app = useApp();
    const [byPhone, setByPhone] = useState(true);
    const navigate = useNavigate();
    const fetch = useFetch();
    const { t } = useTranslation();
    const [form] = useForm();

    const onFinish = async (data: any) => {
        const result = await fetch.request<ILoginResponse>({
            url: '/login',
            data,
            method: 'post',
        });
        if (result.success && result.data) {
            app.authSucceed(result.data.token);
        }
    };

    const onRegisterClick = () => navigate('/register');

    const onRestoreClick = () => navigate('/forgot-password');

    return (
        <AuthorizationContent title={t('authorization')}>
            <Space className="w-100" direction="vertical">
                <Button type="link" onClick={() => setByPhone((value) => !value)}>
                    {byPhone ? t('authorizationByEmail') : t('authorizationByPhone')}
                </Button>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Row gutter={[30, 30]}>
                        {byPhone && (
                            <Col xs={24}>
                                <Form.Item
                                    label={t('phone')}
                                    name="phone"
                                    rules={[{
                                        required: true,
                                        message: getRequiredFieldMessage(t('phone')),
                                    }]}
                                >
                                    <PhoneInput />
                                </Form.Item>
                            </Col>
                        )}
                        {!byPhone && (
                            <Col xs={24}>
                                <Form.Item
                                    label={t('email')}
                                    name="email"
                                    rules={[
                                        { required: true, message: getRequiredFieldMessage(t('email')) },
                                        { type: 'email', message: getEnteredIncorrectlyFieldMessage(t('email')) },
                                    ]}
                                >
                                    <Input type="email" />
                                </Form.Item>
                            </Col>
                        )}
                        <Col xs={24}>
                            <Form.Item
                                label={t('password')}
                                name="password"
                                rules={[{
                                    required: true,
                                    message: getRequiredFieldMessage(t('password')),
                                }]}
                            >
                                <FormItemWrapper component={Input.Password} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Space className="w-100" direction="vertical" size="large">
                                <Button
                                    className="w-100 ant-btn-dark"
                                    type="default"
                                    htmlType="submit"
                                    size="large"
                                    loading={fetch.loading}
                                >
                                    {t('login')}
                                </Button>
                                <Space className="w-100 row-space-between" direction="horizontal" wrap>
                                    <Button className="w-100 pl-0" type="link" onClick={onRegisterClick}>
                                        {t('registration')}
                                    </Button>
                                    <Button className="w-100 pr-0" type="link" onClick={onRestoreClick}>
                                        {t('passwordForgot')}
                                    </Button>
                                </Space>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Space>
        </AuthorizationContent>
    );
}

export default LoginPage;
