import { Button, Card, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Table from '../../components/table';
import { usePurchaseItems } from '../../hooks/usePurchaseItems';
import { IPurchaseItem } from '../../types/purchase';
import EditIcon from '../../assets/icons/edit';
import TrashIcon from '../../assets/icons/trash';

function PurchaseItemsPage() {
    const { id } = useParams();
    const { data, deleteRow, loading } = usePurchaseItems(id);
    const { t } = useTranslation();

    const actions = (row: IPurchaseItem) => (
        <Space>
            <Link to={`/purchases/${id}/items/${row.id}`}>
                <Button icon={<EditIcon />} type="primary" />
            </Link>
            <Button
                icon={<TrashIcon />}
                type="primary"
                onClick={() => deleteRow(row.id)}
            />
        </Space>
    );

    const extra = (
        <Link to={`/purchases/${id}/items/create`}>
            <Button icon={<PlusOutlined />} type="primary" />
        </Link>
    );

    return (
        <Card extra={extra} title={t('goodsList')}>
            <Table
                loading={loading}
                dataSource={data?.list}
                pagination={{
                    current: data?.currentPage,
                    total: data?.total,
                    pageSize: data?.perPage,
                }}
                columns={[{
                    align: 'right',
                    dataIndex: 'id',
                    title: '№',
                }, {
                    dataIndex: 'name',
                    title: t('goodName'),
                }, {
                    dataIndex: 'count',
                    title: t('orderPackageItemsCount'),
                }, {
                    dataIndex: 'price',
                    title: t('orderPackageCost'),
                }, {
                    render: actions,
                    width: 100,
                }]}
            />
        </Card>
    );
}

export default PurchaseItemsPage;
