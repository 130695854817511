import { Card, Grid, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CommonOrderImage from '../assets/images/common-order.jpg';
import OrderFromMarketplacesImage from '../assets/images/order-from-marketplaces.jpg';

function ParcelsPage() {
    const { md } = Grid.useBreakpoint();
    const { t } = useTranslation();

    return (
        <div className="container">
            <Card className="card-header-wo-border" bordered={false} title={t('parcels')}>
                <Space align="center" direction={md ? 'horizontal' : 'vertical'} className="w-100" size="large">
                    <Link to="/make-order/marketplaces">
                        <Space align="center" direction="vertical">
                            <img alt={t('shoppingFromStores') as string} className="order-type-btn-image" src={OrderFromMarketplacesImage} />
                            <span>{t('shoppingFromStores')}</span>
                        </Space>
                    </Link>
                    <Link to="/make-order/common">
                        <Space align="center" direction="vertical">
                            <img alt={t('regularShipment') as string} className="order-type-btn-image" src={CommonOrderImage} />
                            <span>{t('regularShipment')}</span>
                        </Space>
                    </Link>
                </Space>
            </Card>
        </div>
    );
}

export default ParcelsPage;
