import {
    Button, Col, Divider, FormInstance, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
    forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import produce from 'immer';
import { PlusOutlined } from '@ant-design/icons';
import { IPurchaseItem } from '../types/purchase';
import PurchaseItemForm from './purchase-item-form';
import Collapse from './collapse';

interface IPurchaseItemsFormProps {
    items: IPurchaseItem[];
}

export interface IPurchaseItemsFormRef {
    getItems: () => IPurchaseItem[];
    validate: () => Promise<boolean>;
}

const PurchaseItemsForm = forwardRef(({ items }: IPurchaseItemsFormProps, ref) => {
    const [newItems, setNewItems] = useState<any[]>([]);
    const forms = useRef<Record<any, FormInstance>>({});
    const { t } = useTranslation();

    useEffect(() => {
        setNewItems([]);
        forms.current = {};
    }, [items]);

    const getItems = () => {
        const keys = Object.keys(forms.current);
        return keys.map((key) => {
            const values = forms.current[key].getFieldsValue();
            values.id = key;
            return values;
        });
    };

    const validateForm = async (key: any) => {
        try {
            await forms.current[key].validateFields();
            return true;
        } catch (err) {
            return false;
        }
    };

    const validate = async () => {
        const keys = Object.keys(forms.current);
        const result = await Promise.all(keys.map((key) => validateForm(key)));
        return !result.includes(false);
    };

    useImperativeHandle(ref, (): IPurchaseItemsFormRef => ({
        getItems,
        validate,
    }));

    const onAddClick = () => {
        setNewItems(produce(newItems, (draft) => {
            draft.push({
                id: `new-${new Date().valueOf()}`,
            });
            return draft;
        }));
    };

    const initFormRef = (formInstance: FormInstance | null, item: IPurchaseItem) => {
        if (formInstance) {
            forms.current[item.id] = formInstance;
        }
    };

    const renderForm = (item: IPurchaseItem) => (
        <PurchaseItemForm ref={(formInstance) => initFormRef(formInstance, item)} initialValues={item} />
    );

    return (
        <Collapse title={t('goodsList')}>
            <Row gutter={[30, 30]}>
                <Col xs={24}>
                    <span>{t('parcelDimensionsAttentionInformation')}</span>
                </Col>
                {items.map((item, index) => (
                    <Col xs={24}>
                        {index > 0 && <Divider style={{ marginTop: 0 }} />}
                        {renderForm(item)}
                    </Col>
                ))}
                {newItems.map((item, index) => (
                    <Col xs={24}>
                        {(index > 0 || items.length > 0) && <Divider style={{ marginTop: 0 }} />}
                        {renderForm(item)}
                    </Col>
                ))}
                <Col xs={24}>
                    <Button icon={<PlusOutlined />} type="primary" onClick={onAddClick}>
                        {t('addGood')}
                    </Button>
                </Col>
            </Row>
        </Collapse>
    );
});

export default PurchaseItemsForm;
