/* eslint-disable no-undef */
import {
    Button, Col, Form, FormInstance, Grid, Input, Modal, Row, Space, TreeSelect,
} from 'antd';
import {
    forwardRef, useEffect, useImperativeHandle, useState, useRef,
    ChangeEventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getRequiredFieldMessage, onInputWheel } from '../../helpers/form';
import { useForm } from '../../hooks/useForm';
import { IOrderPackageItem } from '../../types/order';
import { useCustomsRegulations } from '../../hooks/useCustomsRegulations';
import { useOrderItemTik } from '../../hooks/useOrderItemTik';
import { linearLabels } from './first';

export interface OrderItemFormRef {
    show: (item?: IOrderPackageItem, index?: number) => void;
}

interface OrderItemFormProps {
    onSubmit: (values: OrderItemFormValues) => void;
    orderForm: FormInstance;
}

export interface OrderItemFormValues extends IOrderPackageItem {
    index?: number;
    newRowId?: number;
}

const { useBreakpoint } = Grid;

const OrderItemForm = forwardRef(({ onSubmit, orderForm }: OrderItemFormProps, ref) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<IOrderPackageItem>();
    const [form] = useForm();
    const { i18n, t } = useTranslation();
    const selectedIndex = useRef<number>();
    const { lg } = useBreakpoint();
    const { data, getCustomsRegulations } = useCustomsRegulations();
    const [ruName, setRuName] = useState('');
    const tik = useOrderItemTik(ruName, typeof ruName === 'string' && ruName.length > 0);
    const langTwoLetters = i18n.language.slice(0, 2).toLowerCase() as 'ru' | 'de' | 'en';
    const ruNameChangeTimeout = useRef<NodeJS.Timeout>();

    const hide = () => {
        setSelected(undefined);
        setOpen(false);
    };

    const show = (item?: IOrderPackageItem, index?: number) => {
        selectedIndex.current = index;
        setSelected(item);
        setOpen(true);
    };

    const onFinish = (values: OrderItemFormValues) => {
        if (typeof values.id === 'undefined') {
            values.newRowId = new Date().valueOf();
        }
        onSubmit(values);
        hide();
    };

    const onNameInputChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        form.setFieldValue('tncode', undefined);
        clearTimeout(ruNameChangeTimeout.current);
        ruNameChangeTimeout.current = setTimeout(() => {
            setRuName(target.value);
        }, 500);
    };

    useEffect(() => {
        if (open) {
            getCustomsRegulations(orderForm.getFieldValue('from'));
            form.setFieldsValue({
                ...selected,
                cost_unit: 'euro',
                index: selectedIndex.current,
            });
            setRuName(selected?.ru_name ?? selected?.name ?? '');
        } else {
            form.resetFields();
            selectedIndex.current = undefined;
        }
    }, [open, selected]);

    useImperativeHandle(ref, () => ({
        show,
    }));

    return (
        <Modal
            open={open}
            onCancel={hide}
            title={t('orderPackageInformation')}
            okText={t('save')}
            onOk={form.submit}
            footer={null}
            closable={false}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={[30, 30]}>
                    <Col xs={24}>
                        <span>{t('parcelDimensionsAttentionInformation')}</span>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            extra={t('sanctionedGoodsInfo')}
                            label={t('orderPackageName')}
                            name="name"
                            rules={[
                                { required: true, message: getRequiredFieldMessage(t('orderPackageName')) },
                                { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    {orderForm.getFieldValue('to') === 'RU' && (
                        <>
                            <Col xs={24}>
                                <Form.Item
                                    label={t('orderPackageRuName')}
                                    name="ru_name"
                                    rules={[
                                        { required: true, message: getRequiredFieldMessage(t('orderPackageName')) },
                                    ]}
                                >
                                    <Input onChange={onNameInputChange} />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    name="tncode"
                                    label={t('orderPackageCode')}
                                    rules={[{ required: tik.list.length > 0, message: getRequiredFieldMessage(t('orderPackageCode')) }]}
                                >
                                    <TreeSelect
                                        treeData={tik.list}
                                        loading={tik.loading}
                                    />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                    <Col xs={24}>
                        <Form.Item
                            label={t('orderPackageItemsCount')}
                            name="count"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageItemsCount')) }]}
                        >
                            <Input type="number" onWheel={onInputWheel} min={1} />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            extra={data?.duty}
                            label={lg ? `${t('orderPackageCost')} (${t('itemTotalCost')})` : t('orderPackageCost')}
                            name="cost"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageCost')) }]}
                        >
                            <Input type="number" onWheel={onInputWheel} min={0} />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item label={t('orderPackageItemUrl')} name="url">
                            <Input />
                        </Form.Item>
                    </Col>
                    {orderForm.getFieldValue('from') === 'CN' && (
                        <>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('orderPackageWeight')}
                                    name="weight"
                                    rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageWeight')) }]}
                                >
                                    <Input
                                        onKeyPress={(event) => {
                                            if (!/[0-9,.]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        suffix={linearLabels.kg[langTwoLetters]}
                                        onWheel={onInputWheel}
                                        min={0}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('orderPackageWidth')}
                                    name="width"
                                    rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageWidth')) }]}
                                >
                                    <Input
                                        suffix={linearLabels.cm[langTwoLetters]}
                                        type="number"
                                        onWheel={onInputWheel}
                                        min={0}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('orderPackageLength')}
                                    name="length"
                                    rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageLength')) }]}
                                >
                                    <Input
                                        suffix={linearLabels.cm[langTwoLetters]}
                                        type="number"
                                        onWheel={onInputWheel}
                                        min={0}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={t('orderPackageHeight')}
                                    name="height"
                                    rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageHeight')) }]}
                                >
                                    <Input
                                        suffix={linearLabels.cm[langTwoLetters]}
                                        type="number"
                                        onWheel={onInputWheel}
                                        min={0}
                                    />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                    <Col className="text-right" xs={24}>
                        <Space className="form-action-buttons" direction="horizontal" size="large">
                            <Button onClick={hide} type="link">{t('cancel')}</Button>
                            <Button htmlType="submit" type="primary">{t('save')}</Button>
                        </Space>
                    </Col>
                </Row>
                <Form.Item hidden name="id" />
                <Form.Item hidden name="index" />
                <Form.Item hidden name="cost_unit" />
            </Form>
        </Modal>
    );
});

export default OrderItemForm;
