import { Spin } from 'antd';
import { Outlet } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import AuthedLayout from './authed-layout';
import MakeOrderWrapper from './make-order-wrapper';

function AuthedRoutes() {
    const user = useUser({ canFetch: true, canLogoutBy401: true, showFetchMessages: false });

    if (user.loading) {
        return (
            <div className="d-flex flex-1 align-items-center justify-content-center">
                <Spin size="large" />
            </div>
        );
    }
    if (typeof user.info !== 'undefined') {
        return (
            <MakeOrderWrapper>
                <AuthedLayout>
                    <Outlet />
                </AuthedLayout>
            </MakeOrderWrapper>
        );
    }
    return null;
}

export default AuthedRoutes;
