import { useEffect, useState } from 'react';
import { DefaultOptionType } from 'antd/lib/select';
import { useFetch } from './useFetch';

interface IOrderItemTik {
    code: string;
    count: number;
    notes: string[];
}

export const useOrderItemTik = (name: string, ready = false) => {
    const { loading, request } = useFetch();
    const [list, setList] = useState<DefaultOptionType[]>([]);

    const getList = async () => {
        if (name.length > 0 && ready) {
            const response = await request<IOrderItemTik[]>({
                url: '/order-packages/tik/search',
                method: 'post',
                data: { name },
            });
            if (response.success && Array.isArray(response.data)) {
                setList(response.data.map((el) => ({
                    label: el.code,
                    value: el.code,
                    children: Array.isArray(el.notes) ? el.notes.map((note, index) => ({
                        label: note,
                        value: `${el.code}${index}`,
                        selectable: false,
                    })) : [],
                })));
            } else {
                setList([]);
            }
        }
    };

    useEffect(() => {
        getList();
    }, [name, ready]);

    return {
        list,
        loading,
    };
};
