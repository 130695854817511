import { IOrder } from '../../types/order';
import { useFetch } from '../useFetch';

export interface IRobokassaResponse {
    url: string|null;
    paid: boolean;
}

export const useRobokassa = () => {
    const fetchData = useFetch();

    const getPaymentData = async (order: IOrder): Promise<IRobokassaResponse|undefined> => {
        if (order) {
            const result = await fetchData.request<IRobokassaResponse>({
                url: `/orders/${order.id}/pay`,
                data: {
                    type: 'robokassa',
                },
                method: 'post',
            });
            if (result.success && result.data instanceof Object) {
                return result.data;
            }
        }
        return undefined;
    };

    return {
        getPaymentData,
    };
};
