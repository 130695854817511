import { PropsWithChildren } from 'react';
import OrderContextWrapper from '../context/order';

function MakeOrderWrapper({ children }: PropsWithChildren) {
    return (
        <OrderContextWrapper>
            {children}
        </OrderContextWrapper>
    );
}

export default MakeOrderWrapper;
