function MapMarkerIcon() {
    return (
        <span className="anticon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
                <path d="M10.0003 10.0834C9.44779 10.0834 8.91789 9.86392 8.52719 9.47322C8.13649 9.08252 7.91699 8.55262 7.91699 8.00008C7.91699 7.44755 8.13649 6.91764 8.52719 6.52694C8.91789 6.13624 9.44779 5.91675 10.0003 5.91675C10.5529 5.91675 11.0828 6.13624 11.4735 6.52694C11.8642 6.91764 12.0837 7.44755 12.0837 8.00008C12.0837 8.27367 12.0298 8.54458 11.9251 8.79734C11.8204 9.0501 11.6669 9.27977 11.4735 9.47322C11.28 9.66668 11.0503 9.82013 10.7976 9.92483C10.5448 10.0295 10.2739 10.0834 10.0003 10.0834ZM10.0003 2.16675C8.45323 2.16675 6.9695 2.78133 5.87554 3.87529C4.78157 4.96925 4.16699 6.45299 4.16699 8.00008C4.16699 12.3751 10.0003 18.8334 10.0003 18.8334C10.0003 18.8334 15.8337 12.3751 15.8337 8.00008C15.8337 6.45299 15.2191 4.96925 14.1251 3.87529C13.0312 2.78133 11.5474 2.16675 10.0003 2.16675Z" />
            </svg>
        </span>
    );
}

export default MapMarkerIcon;
