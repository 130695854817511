import {
    Button, Dropdown, Layout, PageHeader, Menu, Space, Grid,
} from 'antd';
import {
    MenuOutlined, DownOutlined, CloseOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from '../hooks/useUser';
import RuPNG from '../assets/images/languages/russia.png';
import DePNG from '../assets/images/languages/germany.png';
import UkPNG from '../assets/images/languages/united-kingdom.png';
import LogoNoTextPNG from '../assets/images/logo-no-text.png';

import '../styles/header.less';
import { useApp } from '../hooks/useApp';
import { useLanguage } from '../hooks/useLanguage';
import UserIcon from '../assets/icons/user';
import ExitIcon from '../assets/icons/exit';

interface IHeaderProps {
    drawerOpen: boolean;
    onMenuClick: () => void;
    showMenuBtn: boolean;
}

export const languagesIcons = {
    'ru-RU': <img className="header-language-image" src={RuPNG} alt="Русский" />,
    'de-DE': <img className="header-language-image" src={DePNG} alt="Deutsch" />,
    'en-EN': <img className="header-language-image" src={UkPNG} alt="English" />,
};

const { useBreakpoint } = Grid;

export function LanguagesDropDown() {
    const { i18n } = useTranslation();
    const { changeLanguage } = useLanguage();

    const languages = (
        <Menu
            style={{ width: 170 }}
            activeKey={i18n.language}
            items={[{
                label: 'Русский',
                key: 'ru-RU',
                icon: languagesIcons['ru-RU'],
            }, {
                label: 'Deutsch',
                key: 'de-DE',
                icon: languagesIcons['de-DE'],
            }, {
                label: 'English',
                key: 'en-EN',
                icon: languagesIcons['en-EN'],
            }]}
            onClick={({ key }) => changeLanguage(key)}
        />
    );

    return (
        <Dropdown overlay={languages} placement="bottomLeft">
            <Space>
                {languagesIcons[i18n.language as keyof typeof languagesIcons]}
                <DownOutlined style={{ fontSize: 12 }} />
            </Space>
        </Dropdown>
    );
}

function Header({ drawerOpen, onMenuClick, showMenuBtn }: IHeaderProps) {
    const app = useApp();
    const { info: userInfo } = useUser();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { lg } = useBreakpoint();

    const onUserMenuClick = ({ key }: any) => {
        if (key === 'logout') {
            app.logout();
            return;
        }
        navigate(`/${key}`);
    };

    const userMenu = (
        <Menu
            onClick={onUserMenuClick}
            items={[{
                label: t('profile'),
                key: 'profile',
                icon: <UserIcon className="ant-dropdown-menu-item-icon" width={20} height={20} style={{ marginLeft: -4 }} />,
            }, {
                type: 'divider',
            }, {
                label: t('logout'),
                key: 'logout',
                icon: <ExitIcon className="ant-dropdown-menu-item-icon" width={20} height={20} style={{ marginLeft: -4 }} />,
            }]}
        />
    );

    const extra = (
        <Space size="large">
            <Dropdown overlay={userMenu} placement="bottom">
                <Button className="row-items-centered" type="text" size="small">
                    {!showMenuBtn && (
                        <>
                            {userInfo?.name}
                            {' '}
                            {userInfo?.surname}
                        </>
                    )}
                </Button>
            </Dropdown>
            <LanguagesDropDown />
        </Space>
    );

    const title = (
        <Button hidden={!showMenuBtn} type="text" onClick={onMenuClick} size="small">
            <MenuOutlined />
        </Button>
    );

    return (
        <Layout.Header>
            {lg && (
                <div className="header-container">
                    <PageHeader extra={extra} title={title} />
                </div>
            )}
            {!lg && (
                <div className="mobile-header-container">
                    <PageHeader
                        extra={(
                            <Button className={`mobile-menu-btn${drawerOpen ? ' open' : ''}`} type="link" onClick={onMenuClick}>
                                <div className="mobile-open-menu-icon">
                                    <MenuOutlined />
                                </div>
                                <div className="mobile-close-menu-icon">
                                    <CloseOutlined />
                                </div>
                            </Button>
                        )}
                        title={(
                            <img alt="Telega" className="mobile-header-logo" src={LogoNoTextPNG} />
                        )}
                    />
                </div>
            )}
        </Layout.Header>
    );
}

export default Header;
