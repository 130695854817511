import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { IListResponse } from '../types/list';
import { useFetch } from './useFetch';
import { IPurchase } from '../types/purchase';

interface IUsePurchasesParams {
    canFetch?: boolean;
    inOrderForm?: boolean;
    orderId?: number;
    warehouseId?: number;
}

const defaultParams:IUsePurchasesParams = {
    canFetch: true,
    inOrderForm: false,
};

export const usePurchases = ({
    canFetch, inOrderForm, orderId, warehouseId,
} = defaultParams) => {
    const deleteFetch = useFetch();
    const listFetch = useFetch();
    const [data, setData] = useState<IListResponse<IPurchase>>();
    const [searchParams] = useSearchParams();
    const { i18n, t } = useTranslation();
    const page = searchParams.get('page');

    const deleteRow = (x: number) => {
        Modal.confirm({
            title: t('confirmAction'),
            content: t('deleteConfirmation'),
            onOk: () => deleteFetch.request({
                url: `/purchases/${x}/delete`,
                method: 'get',
            }).then(() => getList()),
            okText: t('delete'),
        });
    };

    const getList = async () => {
        let url = '/purchases';

        if (typeof page === 'string') {
            url += `?page=${page}`;
        }

        const filters = [];

        if (inOrderForm) {
            filters.push({
                column: 'order_id',
                values: typeof orderId !== 'undefined' ? [orderId, null] : [null],
            });
        }

        if (typeof warehouseId !== 'undefined') {
            filters.push({
                column: 'warehouse_id',
                values: [warehouseId],
            });
        }

        const result = await listFetch.request<IListResponse<IPurchase>>({
            url,
            method: 'post',
            data: {
                filters,
                sorters: [{ column: 'id', desc: true }],
            },
        });

        if (result.success && result.data) {
            setData(result.data);
        }
    };

    useEffect(() => {
        if (canFetch) {
            getList();
        }
    }, [canFetch, page, orderId, warehouseId, i18n.language]);

    return {
        data: canFetch ? data : undefined,
        deleteRow,
        getList,
        loading: listFetch.loading,
    };
};
