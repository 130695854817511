import axios, {
    AxiosRequestConfig, AxiosResponse,
} from 'axios';
import { t } from 'i18next';
import { config } from '../constants/config';
import UserTokenService from './user-token';

type TFetchServiceHeaders = Record<string, string>;

interface IFetchServiceResponse<T> {
    success: boolean,
    message: string,
    data: T|null,
    status: number;
    errors?: Record<string, string[]>;
}

export type TFetchServiceRequestConfig = AxiosRequestConfig & {
    headers?: TFetchServiceHeaders;
};

axios.interceptors.response.use(undefined, (err: {response: AxiosResponse}) => {
    if ([401, 404, 422, 403].includes(err.response.status)) {
        return err.response;
    }
    return Promise.reject(err);
});

class FetchService {
    /**
     * Получить заголовки HTTP-запроса
     * @param predefined - заголовки запроса { key: value }
     * @returns TFetchServiceHeaders
     */
    public static getHeaders = (predefined?: TFetchServiceHeaders): TFetchServiceHeaders => {
        const headers: TFetchServiceHeaders = {
            'Content-Type': 'application/json',
            ...predefined,
        };

        const userToken = UserTokenService.getToken();
        if (typeof userToken === 'string') {
            headers.Authorization = `Bearer ${userToken}`;
        }

        return headers;
    };

    /**
     * Создать запрос на сервер
     * @param reqConfig - параметры запроса
     * @returns Promise<IFetchServiceResponse<T>>
     */
    public static makeRequest = async <T = any>(reqConfig: TFetchServiceRequestConfig): Promise<IFetchServiceResponse<T>> => {
        try {
            const { data, status } = await axios({
                baseURL: config.apiUrl,
                ...reqConfig,
                headers: FetchService.getHeaders(reqConfig.headers),
                withCredentials: true,
            });
            return {
                ...data,
                status,
            };
        } catch (err) {
            return {
                success: false,
                data: null,
                message: t('httpRequestServeError'),
                status: 500,
            };
        }
    };
}

export default FetchService;
