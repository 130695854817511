import {
    Button, Card, Grid, Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PlusOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { useRef, useState } from 'react';
import produce from 'immer';
import Table from '../../components/table';
import { usePurchases } from '../../hooks/usePurchases';
import { IPurchase } from '../../types/purchase';
import { useTableLayoutProcessor } from '../../hooks/useTableLayoutProcessor';
import PurchaseItemFormModal, { IPurchaseItemFormModalInstance } from '../../components/purchase-item-form-modal';
import EditIcon from '../../assets/icons/edit';
import TrashIcon from '../../assets/icons/trash';
import PurchaseItemsTable, { IPurchaseItemsTableRef } from '../../components/purchase-items-table';

const { useBreakpoint } = Grid;

function PurchasesPage() {
    const {
        data, deleteRow, getList, loading,
    } = usePurchases();
    const { t } = useTranslation();
    const { md } = useBreakpoint();
    useTableLayoutProcessor();
    const itemFormModal = useRef<IPurchaseItemFormModalInstance>();
    const [visibleItems, setVisibleItems] = useState<number[]>([]);
    const itemTables = useRef<Record<number, IPurchaseItemsTableRef>>({});
    const targetRow = useRef<IPurchase>();

    const extra = (
        <Link to="/purchases/create">
            <Button icon={<PlusOutlined />} type="primary" style={md ? undefined : { height: 48, width: 48, padding: 0 }}>
                {md && <span>{t('addPurchase')}</span>}
            </Button>
        </Link>
    );

    const onAddItemClick = (row: IPurchase) => {
        itemFormModal.current?.open(row);
        targetRow.current = row;
    };

    const onItemFormHide = () => {
        if (targetRow.current instanceof Object) {
            itemTables.current[targetRow.current.id]?.update();
            getList();
        }
    };

    const onItemsShowClick = (id: number) => {
        setVisibleItems(produce(visibleItems, (draft) => {
            const index = draft.findIndex((el) => el === id);
            if (index > -1) {
                draft.splice(index, 1);
                delete itemTables.current[id];
            } else {
                draft.push(id);
            }
            return draft;
        }));
    };

    const onItemsTableRef = (purchase: IPurchase, ref: IPurchaseItemsTableRef) => {
        itemTables.current[purchase.id] = ref;
    };

    const expandedRowRender = (record: IPurchase) => (
        <Space className="w-100" direction="vertical" size="large">
            <Space className="w-100" direction={md ? 'horizontal' : 'vertical'} style={{ justifyContent: 'space-between' }} size="large">
                <Space size="middle">
                    <span>{`${t('status')}:`}</span>
                    <span className="order-status-span">{record.status}</span>
                </Space>
                <Space className="w-100" style={{ justifyContent: 'space-between' }}>
                    <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={() => onAddItemClick(record)}
                    >
                        {t('addGood')}
                    </Button>
                    <Space className="table-actions-column" direction="vertical" size="small" style={{ justifyContent: 'space-between' }}>
                        <Button
                            icon={visibleItems.includes(record.id) ? <UpOutlined /> : <DownOutlined />}
                            type="link"
                            onClick={() => onItemsShowClick(record.id)}
                        />
                        <Link to={`/purchases/${record.id}`}>
                            <Button icon={<EditIcon />} type="link" />
                        </Link>
                        <Button icon={<TrashIcon />} type="link" onClick={() => deleteRow(record.id)} />
                    </Space>
                </Space>
            </Space>
            {visibleItems.includes(record.id) && (
                <PurchaseItemsTable
                    ref={(ref: any) => onItemsTableRef(record, ref)}
                    purchase={record}
                    onFormHide={getList}
                />
            )}
        </Space>
    );

    return (
        <div className="container">
            <Card className="card-header-wo-border" bordered={false} extra={extra} title={t('myPurchases')}>
                <Table
                    rowKey="id"
                    expandable={{
                        fixed: true,
                        expandedRowKeys: data?.list.map((el) => el.id),
                        expandedRowRender,
                    }}
                    scroll={{ x: true }}
                    loading={loading}
                    columns={[{
                        align: 'right',
                        dataIndex: 'id',
                        title: '№',
                        width: 100,
                        render: (val: number) => <b>{val}</b>,
                    }, {
                        dataIndex: 'name',
                        title: t('goodName'),
                    }, {
                        dataIndex: 'shop_name',
                        title: t('shopName'),
                    }, {
                        dataIndex: 'warehouse',
                        title: t('warehouse'),
                    }, {
                        dataIndex: 'tracking_code',
                        title: t('trackingNumber'),
                    }, {
                        dataIndex: 'total_weight',
                        title: t('totalWeight'),
                    }, {
                        dataIndex: 'status',
                        title: t('status'),
                    }, {
                        dataIndex: 'sum',
                        title: t('sum'),
                    }]}
                    dataSource={data?.list}
                    pagination={{
                        current: data?.currentPage,
                        total: data?.total,
                        pageSize: data?.perPage,
                    }}
                />
            </Card>
            <PurchaseItemFormModal ref={itemFormModal} onHide={onItemFormHide} />
        </div>
    );
}

export default PurchasesPage;
