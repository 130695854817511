import { Button, Checkbox, Space } from 'antd';
import { useRef } from 'react';
import { FileDoneOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TermsOfService, { ITermsOfServiceRef } from './terms-of-service';
import { useOrderTerms } from '../hooks/useOrderTerms';
import { IOrder } from '../types/order';

interface IOrderTermsOfServiceButtonProps {
    order: IOrder
    onTermsAccepted: () => void;
    tableAction?: boolean;
}

function OrderTermsOfServiceButton({ onTermsAccepted, order, tableAction }: IOrderTermsOfServiceButtonProps) {
    const { acceptTermsOfService, loading: termsLoading } = useOrderTerms(order);
    const termsOfServiceRef = useRef<ITermsOfServiceRef>();
    const { t } = useTranslation();

    const onAccepted = async () => {
        const result = await acceptTermsOfService();
        if (result) {
            termsOfServiceRef.current?.hide();
            if (typeof onTermsAccepted === 'function') {
                onTermsAccepted();
            }
        }
    };

    const onClick = () => termsOfServiceRef.current?.show();

    if (!order?.terms_of_service_accepted || !tableAction) {
        return (
            <>
                {!tableAction && (
                    <Space>
                        <Checkbox
                            checked={order.terms_of_service_accepted}
                            onChange={onAccepted}
                        />
                        <Button type="link" onClick={onClick}>
                            {t('acceptTermsOfService')}
                        </Button>
                    </Space>
                )}
                {tableAction && (
                    <Button
                        onClick={onClick}
                        type="link"
                        icon={tableAction ? <FileDoneOutlined /> : undefined}
                        title={t('acceptTermsOfService') as string}
                    >
                        {!tableAction && t('acceptTermsOfService')}
                    </Button>
                )}
                <TermsOfService
                    ref={termsOfServiceRef}
                    acceptButtonProps={{ loading: termsLoading }}
                    defaultOpen={false}
                    onAccepted={onAccepted}
                    onCanceled={termsOfServiceRef.current?.hide}
                />
            </>
        );
    }

    return null;
}

export default OrderTermsOfServiceButton;
