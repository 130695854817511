import { useEffect } from 'react';
import {
    Button,
    Card, Col, Form, Input, Row, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { getRequiredFieldMessage, onInputWheel } from '../../helpers/form';
import { usePurchaseItem } from '../../hooks/usePurchaseItem';
import { usePackageUnits } from '../../hooks/usePackageUnits';

function PurchaseItemFormPage() {
    const {
        form, formData, formDataLoading, save, saveLoading,
    } = usePurchaseItem();
    const packageUnits = usePackageUnits(true);
    const { t } = useTranslation();

    useEffect(() => {
        if (typeof formData === 'undefined') {
            form.resetFields();
            form.setFieldsValue({
                weight_unit: packageUnits.data?.weight[0].value,
                width_unit: packageUnits.data?.linear[0].value,
                length_unit: packageUnits.data?.linear[0].value,
                height_unit: packageUnits.data?.linear[0].value,
                currency: 'euro',
                // currency: packageUnits.data?.cost[0]?.value,
            });
        }
    }, [formData, packageUnits.data?.cost]);

    const onPackageItemUnitChange = (key: string, value: string) => form.setFieldValue(key, value);

    return (
        <Card loading={formDataLoading} title={t('goods')}>
            <Form form={form} layout="vertical" onFinish={save}>
                <Form.Item>
                    <b>{t('parcelDimensionsAttentionInformation')}</b>
                </Form.Item>
                <Row gutter={10}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="name"
                            label={t('goodName')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('name')) }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="count"
                            label={t('orderPackageItemsCount')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageItemsCount')) }]}
                        >
                            <Input type="number" onWheel={onInputWheel} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="url"
                            label={t('orderPackageItemUrl')}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageItemUrl')) }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="price"
                            label={`${t('orderPackageCost')} (${t('perItem')})`}
                            rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageCost')) }]}
                        >
                            <Input addonAfter={(
                                <Select
                                    open={false}
                                    key={packageUnits.data?.cost[0].value}
                                    defaultValue="euro"
                                    // defaultValue={formData?.currency || packageUnits.data?.cost[0].value}
                                    options={packageUnits.data?.cost}
                                    onSelect={(val: any) => form.setFieldValue('currency', val)}
                                    loading={packageUnits.loading}
                                />
                            )}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={10}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={`${t('orderPackageWeight')} (${t('perItem')})`}
                                    name="weight"
                                    rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageWeight')) }]}
                                >
                                    <Input
                                        addonAfter={(
                                            <Select
                                                key={packageUnits.data?.weight[0].value}
                                                defaultValue={formData?.weight_unit || packageUnits.data?.weight[0].value}
                                                loading={packageUnits.loading}
                                                options={packageUnits.data?.weight}
                                                onSelect={(value: any) => onPackageItemUnitChange('weight_unit', value)}
                                            />
                                        )}
                                        onKeyPress={(event) => {
                                            if (!/[0-9,.]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onWheel={onInputWheel}
                                    />
                                </Form.Item>
                                <Form.Item hidden name="weight_unit" />
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={`${t('orderPackageWidth')} (${t('perItem')})`}
                                    name="width"
                                    rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageWidth')) }]}
                                >
                                    <Input addonAfter={(
                                        <Select
                                            key={`${packageUnits.data?.linear[0].value}width_unit`}
                                            defaultValue={formData?.width_unit || packageUnits.data?.linear[0].value}
                                            loading={packageUnits.loading}
                                            options={packageUnits.data?.linear}
                                            onSelect={(value: any) => onPackageItemUnitChange('width_unit', value)}
                                        />
                                    )}
                                    />
                                </Form.Item>
                                <Form.Item hidden name="width_unit" />
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={`${t('orderPackageLength')} (${t('perItem')})`}
                                    name="length"
                                    rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageLength')) }]}
                                >
                                    <Input addonAfter={(
                                        <Select
                                            key={`${packageUnits.data?.linear[0].value}length_unit`}
                                            defaultValue={formData?.length_unit || packageUnits.data?.linear[0].value}
                                            loading={packageUnits.loading}
                                            options={packageUnits.data?.linear}
                                            onSelect={(value: any) => onPackageItemUnitChange('length_unit', value)}
                                        />
                                    )}
                                    />
                                </Form.Item>
                                <Form.Item hidden name="length_unit" />
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={`${t('orderPackageHeight')} (${t('perItem')})`}
                                    name="height"
                                    rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageHeight')) }]}
                                >
                                    <Input addonAfter={(
                                        <Select
                                            key={`${packageUnits.data?.linear[0].value}height_unit`}
                                            defaultValue={formData?.height_unit || packageUnits.data?.linear[0].value}
                                            loading={packageUnits.loading}
                                            options={packageUnits.data?.linear}
                                            onSelect={(value: any) => onPackageItemUnitChange('height_unit', value)}
                                        />
                                    )}
                                    />
                                </Form.Item>
                                <Form.Item hidden name="height_unit" />
                            </Col>
                        </Row>
                    </Col>
                    <Form.Item name="currency" hidden />
                </Row>
                <Button htmlType="submit" type="primary" loading={saveLoading}>
                    {t('save')}
                </Button>
            </Form>
        </Card>
    );
}

export default PurchaseItemFormPage;
