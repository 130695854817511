import { Modal } from 'antd';
import React, {
    useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import PurchaseForm from './purchase-form';

interface IPurchaseFormModalProps {
    onSaved?: () => void;
    warehouseId?: number;
}

export interface IPurchaseFormModalRef {
    show: (id?: number | string) => void;
}

const PurchaseFormModal = React.forwardRef(({ onSaved, warehouseId }: IPurchaseFormModalProps, ref: any) => {
    const [open, setOpen] = useState(false);
    const rowId = useRef<number | string>();
    const formRef = useRef<any>();

    const hide = () => setOpen(false);

    const show = (id?: number | string) => {
        setOpen(true);
        rowId.current = id;
    };

    useEffect(() => {
        if (!open) {
            rowId.current = undefined;
            formRef.current?.clear();
        }
    }, [open]);

    useImperativeHandle(ref, (): IPurchaseFormModalRef => ({
        show,
    }));

    const onSavedHandler = () => {
        if (typeof onSaved === 'function') {
            onSaved();
        }
        hide();
    };

    return (
        <Modal open={open} closable={false} footer={null}>
            <PurchaseForm
                ref={formRef}
                id={rowId.current}
                onBack={hide}
                onSaved={onSavedHandler}
                warehouseId={warehouseId}
            />
        </Modal>
    );
});

export default PurchaseFormModal;
