function BoxIcon() {
    return (
        <span className="anticon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19">
                <path d="M7.11111 18.4001V10.2668L0 6.15565V14.3112L7.11111 18.4001ZM8.88889 18.4001L16 14.3112V6.15565L8.88889 10.2668V18.4001ZM12.4222 6.17788L15.0444 4.64454L8 0.600098L5.35556 2.11121L12.4222 6.17788ZM8 8.73343L10.6444 7.22232L3.6 3.13343L0.933334 4.66676L8 8.73343Z" />
            </svg>
        </span>
    );
}

export default BoxIcon;
