import { useEffect } from 'react';
import PusherSocket from '../../services/pusher';
import { useUser } from '../useUser';

interface IUseOrdersPusher {
    onOrdersUpdated?: () => void;
}

export const useOrdersPusher = ({ onOrdersUpdated }: IUseOrdersPusher = {}) => {
    const { info } = useUser();

    const onOrdersUpdatedHandler = () => {
        if (typeof onOrdersUpdated === 'function') {
            onOrdersUpdated();
        }
    };

    const openSocket = () => {
        const conn = new PusherSocket();
        conn.registerMessageListener('orders-updated', onOrdersUpdatedHandler);
        conn.open(`orders-user-${info?.id}`);
    };

    useEffect(() => {
        openSocket();
    }, []);
};
