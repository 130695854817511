import { FormInstance } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useFetch } from './useFetch';

export const useMemberAutocomplete = (form: FormInstance, type: string) => {
    const listFetch = useFetch();
    const memberFetch = useFetch();
    const [list, setList] = useState<DefaultOptionType[]>([]);

    const getList = async () => {
        const result = await listFetch.request<DefaultOptionType[]>({
            url: '/order-members/autocomplete/select',
        });
        if (result.success && Array.isArray(result.data)) {
            setList(result.data);
        }
    };

    const onSelect = async (value: number) => {
        const result = await memberFetch.request({
            url: `/order-members/${value}`,
        });
        if (result.success) {
            form.setFieldsValue({
                ...result.data,
                passport_date: typeof result.data.passport_date === 'string' ? moment(result.data.passport_date) : undefined,
                type,
                order_id: form.getFieldValue('order_id'),
            });
        }
    };

    useEffect(() => {
        getList();
    }, []);

    return {
        list: {
            data: list,
            loading: listFetch.loading,
        },
        selection: {
            onSelect,
            loading: memberFetch.loading,
        },
    };
};
