import LanguageService from './services/language';
import AntdContextWrapper from './context/antd';

import './styles/styles.less';

import AppRouter from './router';
import AppContextWrapper from './context/app';

LanguageService();

function App() {
    return (
        <AntdContextWrapper>
            <AppContextWrapper>
                <AppRouter />
            </AppContextWrapper>
        </AntdContextWrapper>
    );
}

export default App;
