/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import { Table as ATable, TableProps } from 'antd';
import { useNavigate } from 'react-router-dom';

function Table(props: TableProps<any>) {
    const navigate = useNavigate();

    const onPageChange = (page:number) => {
        if (
            (typeof props.pagination === 'boolean' && props.pagination)
            || typeof props.pagination === 'undefined'
            || props.pagination instanceof Object
        ) {
            navigate(`?page=${page}`);
        }
    };

    return (
        <ATable
            {...props}
            pagination={{
                ...props.pagination,
                onChange: onPageChange,
            }}
        />
    );
}

export default Table;
