import {
    notification, Modal, Form, Input,
} from 'antd';
import { ChangeEventHandler, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getRequiredFieldMessage } from '../helpers/form';
import { useFetch } from './useFetch';
import { useUser } from './useUser';

export const useUserEmailConfirm = () => {
    const confirmEmailFetch = useFetch();
    const requestCodeFetch = useFetch();
    const user = useUser();
    const { t } = useTranslation();

    const confirmationCode = useRef<string>();
    const modal = useRef<{destroy: Function}>();

    const confirmEmail = async () => {
        const result = await confirmEmailFetch.request({
            url: 'confirm-email',
            method: 'post',
            data: {
                code: confirmationCode.current,
            },
        });
        if (result.success) {
            notification.success({ message: result.message });
            user.getUserInfo();
            if (typeof modal.current?.destroy === 'function') {
                modal.current?.destroy();
            }
            confirmationCode.current = undefined;
        }
    };

    const onConfirmationCodeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        confirmationCode.current = e.target.value;
    };

    const confirmEmailForm = (
        <Form layout="vertical">
            <Form.Item
                required
                label={t('confirmationCode')}
                rules={[{ required: true, message: getRequiredFieldMessage(t('confirmationCode')) }]}
            >
                <Input defaultValue={confirmationCode.current} onChange={onConfirmationCodeChange} />
            </Form.Item>
        </Form>
    );

    const requestCode = async () => {
        const result = await requestCodeFetch.request({
            url: '/request-confirm-email-code',
        });
        if (result.success) {
            modal.current = Modal.confirm({
                content: confirmEmailForm,
                title: result.message,
                okText: t('confirm'),
                cancelText: t('cancel'),
                okButtonProps: {
                    onClick: () => {
                        confirmEmail();
                    },
                    loading: confirmEmailFetch.loading,
                },
            }) as any;
        }
    };

    return {
        confirmEmail: {
            request: confirmEmail,
            loading: confirmEmailFetch.loading,
        },
        requestCode: {
            request: requestCode,
            loading: requestCodeFetch.loading,
        },
    };
};
