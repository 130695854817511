import { useEffect, useRef, useState } from 'react';
import { notification } from 'antd';
import { useFetch } from './useFetch';
import { IPurchase } from '../types/purchase';
import { useForm } from './useForm';
import { IPurchaseItemsFormRef } from '../components/purchase-items-form';
import { IPurchaseFormProps } from '../components/purchase-form';

export const usePurchase = ({ id, onSaved }: IPurchaseFormProps) => {
    const [formData, setFormData] = useState<IPurchase>();
    const formDataFetch = useFetch();
    const saveFetch = useFetch();
    const [form] = useForm();
    const itemForms = useRef<IPurchaseItemsFormRef>();

    const newRow = id === 'create';

    const getFormData = async () => {
        if (!newRow) {
            const result = await formDataFetch.request({
                url: `/purchases/${id}`,
                method: 'get',
            });
            if (result.success) {
                setFormData(result.data);
            }
        }
    };

    const save = async (data: IPurchase) => {
        const itemFormsValidated = itemForms.current ? await itemForms.current?.validate() : true;
        if (itemFormsValidated) {
            data.items = itemForms.current?.getItems() || [];
            const result = await saveFetch.request<IPurchase>({
                url: `/purchases/${newRow ? 'create' : `${id}/update`}`,
                method: 'post',
                data,
            });
            if (result.success) {
                if (typeof onSaved === 'function') {
                    onSaved();
                }
                notification.success({
                    message: result.message,
                });
            }
        }
    };

    useEffect(() => {
        getFormData();
    }, [id]);

    useEffect(() => {
        form.setFieldsValue(formData);
    }, [formData]);

    return {
        form,
        formData,
        formDataLoading: formDataFetch.loading,
        save,
        saveLoading: saveFetch.loading,
        itemForms,
    };
};
