import React from 'react';
import {
    Button,
    Card, Col, Dropdown, Layout, Menu, Row, Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import { languagesIcons } from './header';
import LogoPNG from '../assets/images/logo.png';

import '../styles/authorization.css';
import { useLanguage } from '../hooks/useLanguage';

const { Content } = Layout;

interface IAuthorizationContentProps {
    title: React.ReactNode;
}

function AuthorizationContent({ children, title }: React.PropsWithChildren<IAuthorizationContentProps>) {
    const { t, i18n } = useTranslation();
    const { changeLanguage } = useLanguage();

    const onCloseClick = () => {
        window.location.href = 'https://telega.pro/';
    };

    const languages = (
        <Menu
            style={{ width: 200 }}
            activeKey={i18n.language}
            items={[{
                label: 'Русский',
                key: 'ru-RU',
                icon: languagesIcons['ru-RU'],
            }, {
                label: 'Deutsch',
                key: 'de-DE',
                icon: languagesIcons['de-DE'],
            }, {
                label: 'English',
                key: 'en-EN',
                icon: languagesIcons['en-EN'],
            }]}
            onClick={({ key }) => changeLanguage(key)}
        />
    );

    const languageCode:
    keyof typeof languagesIcons = Object.keys(languagesIcons).includes(i18n.language)
        ? i18n.language as keyof typeof languagesIcons : 'en-EN';

    return (
        <Layout>
            <Content className="authorization-page-content">
                <Space className="authorization-page-header-actions" direction="horizontal" size="large">
                    <Dropdown className="authorization-page-languages-dropdown" overlay={languages} placement="bottomLeft">
                        <Space>
                            {languagesIcons[languageCode]}
                            <DownOutlined style={{ fontSize: 12 }} />
                        </Space>
                    </Dropdown>
                    <Button
                        type="default"
                        shape="circle"
                        icon={<CloseOutlined />}
                        onClick={onCloseClick}
                    />
                </Space>
                <Card bordered={false} className="authorization-card">
                    <Row gutter={[0, 40]}>
                        <Col xs={24} md={0}>
                            <Space align="center" className="w-100" direction="vertical" size="large">
                                <img alt={t('serviceName') as string} className="authorization-page-logo" src={LogoPNG} />
                                <h2 className="mb-0"><b>{title}</b></h2>
                            </Space>
                        </Col>
                        <Col xs={0} md={24}>
                            <div className="d-flex align-items-center">
                                <img alt={t('serviceName') as string} className="authorization-page-logo" src={LogoPNG} />
                                <h2 className="mb-0 w-100 text-center" style={{ marginLeft: -50 }}><b>{title}</b></h2>
                            </div>
                        </Col>
                        <Col xs={24}>
                            {children}
                        </Col>
                    </Row>
                </Card>
            </Content>
        </Layout>
    );
}

export default AuthorizationContent;
