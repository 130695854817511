import {
    HashRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';
import AuthedRoutes from '../containers/authed-routes';
import { UserContextWrapper } from '../context/user';
import { useLanguage } from '../hooks/useLanguage';
import HomePage from '../pages/home';
import LoginPage from '../pages/login';
import MakeOrderPage from '../pages/make-order';
import OrderCreatedPage from '../pages/order-created';
import OrdersPage from '../pages/orders';
import RegisterPage from '../pages/register';
import RestorePassword from '../pages/restore-password';
import SettingsPage from '../pages/settings';
import TrackingPage from '../pages/tracking';
import WelcomePage from '../pages/welcome';
import ForgotPassword from '../pages/forgot-password';
import PurchasesPage from '../pages/purchases';
import PurchaseFormPage from '../pages/purchases/form';
import PurchaseItemsPage from '../pages/purchases/items';
import PurchaseItemFormPage from '../pages/purchases/item-form';
import MyAddressesPage from '../pages/my-addresses';
import ParcelsPage from '../pages/parcels';

function Language() {
    useLanguage(true);
    return null;
}

function AppRouter() {
    return (
        <UserContextWrapper>
            <Router>
                <Language />
                <Routes>

                    {/* Роутинг авторизации */}
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/restore-password" element={<RestorePassword />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/welcome" element={<WelcomePage />} />

                    {/* Роутинг для авторизованных пользователей */}
                    <Route path="/" element={<AuthedRoutes />}>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/profile" element={<HomePage />} />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route path="/orders" element={<OrdersPage />} />
                        <Route path="/tracking" element={<TrackingPage />} />
                        <Route path="/make-order/:type" element={<MakeOrderPage />} />
                        <Route path="/order/:id" element={<MakeOrderPage />} />
                        <Route path="/order-created" element={<OrderCreatedPage />} />
                        <Route path="/purchases" element={<PurchasesPage />} />
                        <Route path="/purchases/:id" element={<PurchaseFormPage />} />
                        <Route path="/purchases/:id/items" element={<PurchaseItemsPage />} />
                        <Route path="/purchases/:id/items/:itemId" element={<PurchaseItemFormPage />} />
                        <Route path="/my-addresses" element={<MyAddressesPage />} />
                        <Route path="/parcels" element={<ParcelsPage />} />
                    </Route>

                </Routes>
            </Router>
        </UserContextWrapper>
    );
}

export default AppRouter;
