import {
    Button, Col, Form, Input, notification, Row, Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AuthorizationContent from '../components/authorization-content';
import { getEnteredIncorrectlyFieldMessage, getRequiredFieldMessage } from '../helpers/form';
import { useForm } from '../hooks/useForm';
import { useFetch } from '../hooks/useFetch';

interface IForgotPasswordResponse {
    email: string;
}

function ForgotPassword() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [form] = useForm();
    const fetch = useFetch();
    const onAuthorizationClick = () => navigate('/login');

    const onFinish = async (data: any) => {
        const result = await fetch.request<IForgotPasswordResponse>({
            url: '/forgot-password',
            data,
            method: 'post',
        });
        if (result.success) {
            notification.success({
                message: t('passwordRestore'),
                description: `${t('passwordRestoreMessage')} ${data.email}`,
                duration: 10,
            });
        }
    };

    return (
        <AuthorizationContent title={t('passwordRestore')}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={[30, 30]}>
                    <Col xs={24}>
                        <Form.Item
                            label={t('email')}
                            name="email"
                            rules={[
                                { required: true, message: getRequiredFieldMessage(t('email')) },
                                { type: 'email', message: getEnteredIncorrectlyFieldMessage(t('email')) },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Space className="w-100" direction="vertical" size="large">
                            <Button
                                className="w-100 ant-btn-dark"
                                type="default"
                                htmlType="submit"
                                size="large"
                            >
                                {t('restore')}
                            </Button>
                            <Button
                                loading={fetch.loading}
                                type="link"
                                onClick={onAuthorizationClick}
                            >
                                {t('authorization')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </AuthorizationContent>
    );
}

export default ForgotPassword;
