import { useLayoutEffect } from 'react';

export const useTableLayoutProcessor = () => {
    useLayoutEffect(() => {
        const tables = document.querySelectorAll('.ant-table');

        tables.forEach((table) => {
            const tableContainer = table.querySelector('.ant-table-container') as HTMLDivElement;
            const expandedRows = table.querySelectorAll<HTMLDivElement>('.ant-table-expanded-row-fixed');
            expandedRows.forEach((el: HTMLDivElement) => {
                setTimeout(() => {
                    el.style.width = `${tableContainer.offsetWidth - 42}px`;
                }, 250);
            });
        });
    });
};
