import { Input, Select } from 'antd';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import {
    ChangeEventHandler, useEffect, useLayoutEffect, useState,
} from 'react';
import { countries } from 'country-flag-icons';
import Flags from 'country-flag-icons/react/1x1';
import { getCountryCodeFromPhoneCode, getPhoneCountryCode, getPhoneCountryCodes } from '../helpers/form';

function PhoneInput({
    defaultCountry, country, value, onChange, id,
}: any) {
    const [phone, setPhone] = useState<string>();
    const [prefix, setPrefix] = useState(
        typeof defaultCountry === 'string' || typeof country === 'string' ? `+${getCountryCallingCode((country || defaultCountry) as CountryCode)}` : '+7',
    );

    useEffect(() => {
        if (typeof phone === 'string') {
            onChange(`${prefix}${phone}`);
        }
    }, [phone, prefix]);

    useEffect(() => {
        if (typeof value === 'string') {
            const phoneCountryCode = getPhoneCountryCode(value);
            setPhone(value.replace(phoneCountryCode || prefix, ''));
            if (
                typeof country === 'undefined'
                && phoneCountryCode.length > 0
                && prefix !== phoneCountryCode
            ) {
                setPrefix(phoneCountryCode);
            }
        }
    }, [value]);

    useEffect(() => {
        if (typeof country === 'string') {
            const code = getCountryCallingCode(country as CountryCode);
            if (code) {
                setPrefix(`+${code}`);
            }
        }
    }, [country]);

    useLayoutEffect(() => {
        if (id) {
            const labelEl = document.querySelector(`.ant-form-item-label label[for=${id}]`);
            if (labelEl) {
                const formItem = labelEl.parentElement?.parentElement;
                if (formItem) {
                    const selectEl = formItem.querySelector('.ant-select-selector') as HTMLDivElement;
                    if (selectEl) {
                        labelEl.parentElement.style.left = `${selectEl.offsetWidth}px`;
                    }
                }
            }
        }
    });

    const onChangeFN: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPhone(e.target.value.replace(/[^0-9]/g, ''));
    };

    const renderCountryFlag = (phoneCode: string) => {
        const countryCode = getCountryCodeFromPhoneCode(phoneCode);
        if (typeof countryCode === 'string' && countries.includes(countryCode)) {
            // @ts-ignore
            const Flag = Flags[countryCode];
            return (
                <div className="phone-input-country-flag">
                    <Flag />
                </div>
            );
        }
        return null;
    };

    return (
        <Input.Group className="phone-input" compact style={{ display: 'flex' }}>
            <Select open={typeof country === 'string' ? false : undefined} showArrow={false} onSelect={setPrefix} value={prefix} showSearch>
                {getPhoneCountryCodes().map((code) => (
                    <Select.Option value={`+${code}`}>
                        {renderCountryFlag(code)}
                        {`+${code}`}
                    </Select.Option>
                ))}
            </Select>
            <Input
                value={phone}
                onChange={onChangeFN}
                type="numeric"
            />
        </Input.Group>
    );
}

export default PhoneInput;
