export const enTerms = `
<p>
    1. Telega does not accept for carriage goods that, at its discretion, are considered dangerous and/or may cause harm
    to the cargo of other Customers, as well as goods containing items prohibited by the legislation of the destination
    countries. (You can find a complete list of such prohibitions and restrictions at <a href="https://eur-lex.europa.eu" target="_blank">https://eur-lex.europa.eu</a>)
</p>
<p>
    2. For export orders, the sender, an individual or a legal entity, guarantees that he personally prepared the cargo
    for transportation or for the provision of other services in a protected place, that the cargo was prepared by a
    designated employee of the sender and that there was no unauthorized access to the cargo during preparation, storage
    and transportation until the cargo was accepted for transportation.
</p>
<p>
    3. The Customer is obliged to familiarize himself with the list of dangerous, prohibited and restricted goods when
    placing an order. If the Client has doubts about the relevance of his cargo to the list of dangerous, prohibited or
    restricted for transportation, the Client is obliged to contact the support hotline by phone +49 40 67555165 or by
    email order@telega.pro
</p>
<p>
    4. Customers agree with the right of Telega, authorized state bodies, including customs to open and inspect the
    goods in order to comply with the law or eliminate possible damage to other goods, as well as in case of suspicion
    of an unacceptable or dangerous attachment.
</p>
<p>
    5. The requirements for Shipments accepted for delivery are set out in the Standards (requirements) for weight,
    dimensions, packaging and labeling of shipments, which can be found in the Client's personal account on the Website
    <a href="https://telega.pro" target="_blank">www.telega.pro</a>. Weight verification, density measurement and
    dimensions measurement are not carried out during
    acceptance. Weighing, density measurement, measurement of dimensions, as well as checking of investments for the
    possibility of export from the Russian Federation / import into the receiving country is carried out at the sorting
    center.
</p>
<p>
    5.1. In case of discrepancy of the data on the weight and (or) dimensions provided by the Customer when creating the
    order with the actual weight and (or) dimensions installed at the sorting center, the physical weight, dimensions of
    the Shipment installed at the sorting center are taken as the basis. The weight and/or dimensions are rounded up to
    the nearest value in the direction of increase. Telega has the right to recalculate and set the amount to be paid by
    the Client based on the actual weight, dimensions of the Shipment installed at the sorting center, informing the
    Client by email specified during registration of the personal account (or use other means to inform the Client). The
    Client is obliged to pay the billed amount by Telega, while the delivery period is extended by the period of
    performance of the specified obligation by the Client. If the Customer refuses to pay the amount billed by Telega
    according to the actual weight and (or) dimensions installed at the sorting center, Telega performs actions with the
    cargo in accordance with clauses 6 of this offer.
</p>
<p>
    5.2. If the goods are prohibited for shipment (export customs clearance, import customs clearance in the country of
    destination, permissible goods for air transportation, etc.), then actions will be performed with it in accordance
    with paragraphs 6 of this offer.
</p>
<p>
    6. If there is a technical possibility, placing an order in the personal account, the Client determines what to do
    with the Shipment if there are reasons preventing the execution of the Client's request (for example, the goods
    turned out to be prohibited for shipment, the client refused to pay the billed amount for Telega according to the
    actual weight and (or) dimensions installed at the sorting center, The client does not agree with the revision of
    the billed amount, which has changed due to the unstable economic situation, etc.). The client has the right to
    choose in his personal account the following actions that Telega performs with the Shipment in case of specified
    reasons: return to the sender to the point where the parcel was accepted for shipment (if possible); disposal.
</p>
<p>
    7. If there are reasons preventing the implementation of the Client's request, if the shipment has not left the
    country of departure and the Client chose the method of handling the shipment in accordance with clause 6 in his
    Personal Account, Telega performs the procedure chosen by the Client when creating the order – return or disposal.
</p>
<p>
    If there are reasons preventing the implementation of the Client's request, if the shipment has left the destination
    country, Telega will dispose of the shipment.
</p>
<p>
    When returning/disposing of a Shipment, Telega has the right, but is not obliged to notify the customer in advance
    of the return/disposal of such a Shipment.
</p>
<p>
    8. When exporting, in case of return of the shipment to the Client, the Client is obliged to pay for the return
    shipment in the amount of the cost indicated on Telega Website and/or in the Client's personal account. The cargo is
    subject to disposal if the Customer does not receive payment for the return shipment within 30 days from the moment
    of receipt of the notification from Telega about the required payment for the return shipment or change the status
    of the Shipment to "the order has been transferred for refund", "requires refund payment", depending on which moment
    comes earlier. When exporting, the payment for the return shipment is performed by the Client only by transferring
    funds based on the data specified in the personal account, or data sent to the Client by e-mail from the address:
    order@telega.pro.
</p>
<p>
    9. If it is not possible to return the Shipment, or when creating a parcel in the personal account, the Customer
    selected the disposal option, then the Shipment will be disposed of, in which case the cost of the shipment will not
    be reimbursed to the Customer, all the services of Telega are considered properly performed.
</p>
<p>
    10. Telega has the right to refuse to provide services to the Client or to refuse to use simplified means of
    communication (via a call center, in electronic form), including the following reasons: when requesting services, if
    there is a debt for previously rendered services, when requesting services that are not included in the standard
    services of the company, when requesting services with individual or priority service in case of non-compliance with
    official business communication with employees of the company, in case of violation of the rights of employees of
    Telega, including in case of insulting employees of the company, humiliating the honor and dignity of the company's
    employees, using obscene language, threats, etc.
</p>
<p>
    11. Telega provides free storage of express cargo for 8 calendar days (in some cases, the period may be extended
    depending on the delivery partner. To clarify the exact information about the storage period, please contact the
    support service). By agreement of the parties, the storage period may be extended. After the expiration of the
    storage period of the cargo, Telega has the right to dispose of and/or sell the cargo on its own behalf at its
    discretion. The Client confirms that he has been notified and agrees that in the case of the sale or disposal of
    express cargo, the cost of express cargo, as well as the cost of delivery to the Client will not be reimbursed.
</p>
<p>
    12. Telega bears responsibility for shortages, loss, and damage to items in accordance with the Universal Postal
    Convention, approved by th Postal Union Congress.
</p>
<p>
    13. Compensation for loss, shortage or damage: 1. For items with barcode RJ - compensation in the amount of 30 SDR
    (but not more than the cost of investments) and the amount of the tariff fee (without order fee) 2. For items with
    barcode CJ - compensation in the amount of 40 SDR and 4.5 SDR for each kilogram of parcel (but not more than the
    cost of the investment) plus the amount of the tariff fee. 3. For items with the EJ barcode - compensation in the
    amount of 130 SDR (but not more than the cost of the investment) plus the amount of the tariff fee.
</p>
<p>
    14. Payment for services is confirmation of acceptance of the terms of this offer.
</p>
<p>
    15. In case the service Telega provides services for the purchase of goods, a mandatory requirement for the Client
    is the reliable provision of all necessary information. The Client sends to Telega the information about the desired
    product, its characteristics, quantity, a link to the product in the online store, the Client’s contact information
    and other mandatory information. This service was created solely to provide additional opportunities to Customers
    when purchasing goods in foreign online stores. The client independently chooses the seller of the goods and makes a
    decision of the purchase. Payment for the goods is made by the Client to Telega service under his own responsibility
    according to the bank details specified by the service. The product may be subject to a conversion fee, which is
    additionally agreed upon between the Client and the service. The client independently makes decisions about ordering
    goods, purchasing them through the service and other terms of the transaction. Purchasing goods using the service is
    the Client’s right, not his responsibility. The Client is aware and confirms that Telega service is not a party to
    the transaction of purchase and sale of goods between the Client and the online store/marketplace. The duties of
    Telega service include exclusively the transfer of information about the Client’s possible order and the provision
    of services for the delivery of items. Telega service is not responsible for the quality of the product, its
    characteristics, completeness or other issues related to the product, as well as for its delivery to Telega’s
    consolidation warehouse. The service’s obligations to organize shipment arise only after receiving the parcel at the
    service’s warehouse in accordance with the terms of this public offer.
</p>
`;
