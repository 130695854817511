import {
    Modal, Space, Spin, Typography, Button,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import {
    forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../hooks/useFetch';
import TelegaLogoPNG from '../assets/images/logo.png';
import GlsLogoPNG from '../assets/images/gls-logo.png';
import { config } from '../constants/config';
import { IOrder } from '../types/order';

export interface IOrderQrCodeModalRef {
    show: (order: IOrder) => void;
}

const OrderQrCodeModal = forwardRef((_, ref) => {
    const fetch = useFetch();
    const [order, setOrder] = useState<IOrder>();
    const [base64, setBase64] = useState<string>();
    const { t } = useTranslation();

    const getQrCode = async () => {
        if (typeof order?.id === 'number') {
            const result = await fetch.request<{base64: string}>({
                url: `/orders/${order.id}/gls-qr`,
            });
            if (result.success && result.data) {
                setBase64(result.data.base64);
            }
        }
    };

    useEffect(() => {
        getQrCode();
    }, [order?.id]);

    const hide = () => setOrder(undefined);

    const show = setOrder;

    useImperativeHandle(ref, (): IOrderQrCodeModalRef => ({
        show,
    }));

    return (
        <Modal
            open={typeof order?.id === 'number'}
            onCancel={hide}
            footer={null}
        >
            <Spin spinning={fetch.loading}>
                {typeof base64 === 'string' && (
                    <Space direction="vertical">
                        <div className="d-flex justify-content-center">
                            <img
                                alt="QR-Code"
                                className="order-qr-code-img"
                                src={`data:image/png;base64,${base64}`}
                            />
                        </div>
                        <div className="d-flex justify-content-between">
                            <img alt="GLS" src={GlsLogoPNG} style={{ height: 60 }} />
                            <img alt={t('serviceName') as string} src={TelegaLogoPNG} style={{ height: 60 }} />
                        </div>
                        <Typography.Title level={5} className="text-center">
                            {t('orderQrInfo')}
                        </Typography.Title>
                        {order instanceof Object && (
                            <div className="d-flex justify-content-center">
                                <Button
                                    href={`${config.siteUrl}/orders/${order.id}/pdf?token=${order.uuid}`}
                                    icon={<SaveOutlined />}
                                    target="_blank"
                                    type="primary"
                                >
                                    {t('save')}
                                </Button>
                            </div>
                        )}
                    </Space>
                )}
            </Spin>
        </Modal>
    );
});

export default OrderQrCodeModal;
