import { useEffect } from 'react';
import { notification } from 'antd';
import { useFetch } from './useFetch';
import { IOrder } from '../types/order';
import { useForm } from './useForm';

export const useOrderPromoCode = (order: IOrder, onSucceed?: () => void) => {
    const fetch = useFetch();
    const [form] = useForm();

    const apply = async (formData: Record<string, any>) => {
        const result = await fetch.request({
            url: '/promo-codes/apply',
            method: 'post',
            data: {
                ...formData,
                order_id: order.id,
            },
        });
        if (result.success) {
            notification.success({
                message: result.message,
            });
            if (typeof onSucceed === 'function') {
                onSucceed();
            }
        }
    };

    useEffect(() => {
        if (typeof order.promocode?.code === 'string') {
            form.setFieldValue('code', order.promocode.code);
        }
    }, [order.promocode?.code]);

    return {
        apply,
        form,
        loading: fetch.loading,
    };
};
