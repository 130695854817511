import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IUserAddress } from '../types/user';
import { useFetch } from './useFetch';

export const useUserAddress = () => {
    const [data, setData] = useState<IUserAddress>();
    const dataFetch = useFetch();
    const updateFetch = useFetch();
    const { t } = useTranslation();

    const getData = async () => {
        const result = await dataFetch.request<IUserAddress>({
            url: '/user/shipping-address',
        });
        if (result.status && result.data) {
            setData(result.data);
        }
    };

    const update = async (values: Record<string, any>) => {
        const result = await updateFetch.request({
            url: '/user/shipping-address/update',
            method: 'post',
            data: values,
        });
        if (result.success) {
            getData();
            notification.success({
                message: t('userInfoUpdatedMessage'),
            });
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return {
        data,
        loading: dataFetch.loading,
        update,
        updating: updateFetch.loading,
    };
};
