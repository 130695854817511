import { StripeElementLocale } from '@stripe/stripe-js/types/stripe-js';
import { Languages } from '../services/language';

export const getStripeLocale = (lang: Languages): StripeElementLocale => {
    const stripeLanguages: Record<Languages, StripeElementLocale> = {
        'de-DE': 'de',
        'en-EN': 'en',
        'ru-RU': 'ru',
    };
    return stripeLanguages[lang];
};
