import {
    Button, Col, Form, Input, notification, Row, Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthorizationContent from '../components/authorization-content';
import { getEnteredIncorrectlyFieldMessage, getRequiredFieldMessage } from '../helpers/form';
import { useForm } from '../hooks/useForm';
import { useFetch } from '../hooks/useFetch';
import FormItemWrapper from '../containers/form-item-wrapper';

interface IRestorePasswordResponse {
    token: string;
    email: string,
    password: string,
    password_confirmation: string,
}

function RestorePassword() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [form] = useForm();
    const fetch = useFetch();
    const [searchParams] = useSearchParams();
    const restorePasswordToken = searchParams.get('token');

    if (!restorePasswordToken) {
        navigate('/forgot-password');
    }

    const onAuthorizationClick = () => navigate('/login');

    const onFinish = async (data: any) => {
        const result = await fetch.request<IRestorePasswordResponse>({
            url: '/reset-password',
            data: { ...data, token: restorePasswordToken },
            method: 'post',
        });

        if (result.success) {
            notification.success({
                message: t('passwordRestore'),
                description: t('passwordRestoredMessage'),
                duration: 3,
                onClose: () => navigate('/login'),
            });
        }
    };

    return (
        <AuthorizationContent title={t('passwordRestore')}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={[30, 30]}>
                    <Col xs={24}>
                        <Form.Item
                            label={t('email')}
                            name="email"
                            rules={[
                                { required: true, message: getRequiredFieldMessage(t('email')) },
                                { type: 'email', message: getEnteredIncorrectlyFieldMessage(t('email')) },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t('password')}
                            name="password"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('password')) }]}
                        >
                            <FormItemWrapper component={Input.Password} />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t('confirmPassword')}
                            name="password_confirmation"
                            rules={[
                                { required: true, message: getRequiredFieldMessage(t('confirmPassword')) },
                                ({ getFieldValue }) => ({
                                    validator: (_, value) => {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(t('passwordsDoNotMatch') as string));
                                    },
                                }),
                            ]}
                        >
                            <FormItemWrapper component={Input.Password} />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Space className="w-100" direction="vertical" wrap>
                            <Button
                                className="w-100 ant-btn-dark"
                                type="default"
                                htmlType="submit"
                                size="large"
                            >
                                {t('restore')}
                            </Button>
                            <Button type="link" onClick={onAuthorizationClick}>
                                {t('authorization')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </AuthorizationContent>
    );
}

export default RestorePassword;
