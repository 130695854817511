/* eslint-disable react/destructuring-assignment */
import React from 'react';

function FormItemWrapper(props: React.PropsWithChildren<any>) {
    const classList = (props.className || '').split(' ');

    if (typeof props.value === 'string' && props.value.length > 0) {
        classList.push('form-input-has-value');
    }

    return React.createElement(props.component, {
        ...props,
        className: classList.join(' '),
    });
}

export default FormItemWrapper;
