export const ruTerms = `
<p>
    1. Телега не принимает к перевозке грузы, которые по его усмотрению признаны опасными и/или могут причинить вред
    грузам других Клиентов, а также грузы, содержащие запрещенные законодательством стран назначения предметы. (С полным
    перечнем таких запретов и ограничений вы можете ознакомиться по ссылке <a href="https://eur-lex.europa.eu" target="_blank">https://eur-lex.europa.eu</a>)
</p>
<p>
    2. По заказам на экспорт, Отправитель, физическое лицо или юридическое лицо, гарантирует, что собственноручно
    готовил груз к перевозке или к оказанию прочих услуг в защищенном месте, что подготовку груза производил назначенный
    сотрудник отправителя и что к грузу не было несанкционированного доступа во время подготовки, хранения и перевозки
    до момента принятия груза к перевозке.
</p>
<p>
    3. Клиент обязан самостоятельно при оформлении заказа ознакомиться со списком опасных, запрещенных и ограниченных к
    перевозке товаров. В случае, если у Клиента имеются сомнения об относимости его груза к списку опасных, запрещенных
    или ограниченных к перевозке, то Клиент обязан обратиться на горячую линию поддержки по телефону +49 40 67555165 или
    по электронной почте order@telega.pro
</p>
<p>
    4. Клиенты согласны с правом Телега, уполномоченных государственных органов, включая таможенные, в целях соблюдения
    законодательства или устранения возможного вреда другим грузам, а также при подозрении на недопустимое или опасное
    вложение, вскрывать и проверять грузы.
</p>
<p>
    5. Требования к принимаемым к доставке Отправлениям устанавливаются в Стандартах (требованиях) по весу, габаритам,
    упаковке и маркировке отправлений, с которыми можно ознакомиться в личном кабинете Клиента на сайте <a href="https://telega.pro" target="_blank">www.telega.pro</a>.
    Проверка веса, измерение плотности и замер габаритов при приемке не осуществляется. Взвешивание, измерение
    плотности, замер габаритов, а также проверка вложений на предмет возможности вывоза из РФ/ввоза в страну получения
    производится на сортировочном центре.
</p>
<p>
    5.1. В случае расхождения данных о весе и (или) габаритах, предоставленных Клиентом при создании заказа с
    фактическим весом и (или) габаритами, установленными на сортировочном центре, за основу берется физический вес,
    габариты Отправления, установленные на сортировочном центре. Округление веса и (или) габаритов производится до
    ближайшего значения в сторону увеличения. Телега оставляет за собой право пересчитать и выставить сумму к оплате
    Клиентом исходя из фактического веса, габаритов Отправления, установленных на сортировочном центре, проинформировав
    Клиента посредством письма на электронный адрес, указанный при регистрации личного кабинета (или же проинформировав
    любым другим удобным способом). Клиент обязан оплатить выставленную Телега сумму к оплате, при этом срок доставки
    увеличивается на срок исполнения Клиентом указанной обязанности. В случае если Клиент отказывается оплатить
    выставленную Телега сумму к оплате по данным фактического веса и (или) габаритов, установленных на сортировочном
    центре, Телега осуществляет с грузом действия согласно п. 6 настоящей оферты.
</p>
<p>
    5.2. В случае, если товар запрещен к пересылке (экспортное таможенное оформление, импортное таможенное оформление в
    стране назначения, допустимые товары к авиаперевозке и пр.), то с ним будут выполнены действия согласно п. 6
    настоящей оферты.
</p>
<p>
    6. При наличии технической возможности, Клиент при создании заказа в личном кабинете определяет, что делать с
    Отправлением при наличии причин, препятствующих исполнению заявки Клиента (например, товар оказался запрещенным к
    пересылке, клиент отказался оплатить выставленную Телега сумму к оплате по данным фактического веса и (или)
    габаритов, установленных на сортировочном центре, Клиент не согласен с пересмотром суммы к оплате, изменившейся в
    связи с нестабильной экономической ситуацией и др.). Клиент может выбрать в личном кабинете следующие действия,
    которые Телега совершает с Отправлением при наличии указанных причин: возврат отправителю в пункт, где была принята
    посылка к отправке (если возможен); утилизация.
</p>
<p>
    7. При наличии причин, препятствующих исполнению заявки Клиента, в случае, если отправление не успело покинуть
    страну отправления и Клиентом в Личном кабинете был выбран способ обращения с отправлением согласно п.6, Телега
    осуществляет с Отправлением выбранную Клиентом при создании заказа процедуру – возврат или утилизацию.
</p>
<p>
    При наличии причин, препятствующих исполнению заявки Клиента, в случае, если отправление покинуло страну назначения,
    то Телега утилизирует отправление.
</p>
<p>
    При возврате/утилизации Отправления Телега вправе, но не обязан предварительно уведомить клиента о
    возврате/утилизации такого Отправления.
</p>
<p>
    8. При экспорте в случае возврата отправления Клиенту, Клиент обязан оплатить обратную пересылку отправления в
    размере стоимости, указанной на Сайте Телега и/или в личном кабинете Клиента. Груз подлежит утилизации в случае,
    если от Клиента не поступит оплата обратной пересылки в течение 30 дней с момента получения уведомления от Телега о
    необходимости оплаты обратной пересылки или изменения статуса Отправления на «заказ передан на возврат», «требует
    оплаты возврата» в зависимости от того, какой момент наступит раньше. При экспорте оплата обратной пересылки
    выполняется Клиентом только путем перечисления денежных средств на основании данных, указанных в личном кабинете,
    либо данных, направленных Клиенту по электронной почте от адресата: order@telega.pro.
</p>
<p>
    9. Если Отправление не представляется возможным вернуть или при создании посылки в личном кабинете Клиентом была
    выбрана опция утилизации, то Отправление будет утилизировано, в таком случае стоимость отправления Клиенту не
    возмещается, все услуги Телега считаются надлежаще выполненными.
</p>
<p>
    10. Телега вправе отказать Клиенту в оказании услуг или отказать в использовании упрощенных средств коммуникации
    (через колл-центр, в электронном виде) в том числе по следующим причинам:
<ol>
    <li>при запросе услуг, при наличии задолженности за ранее оказанные услуги</li>
    <li>при запросе услуг, не входящих в стандартные услуги компании</li>
    <li>при запросе услуг с индивидуальным или приоритетным обслуживанием</li>
    <li>
        при несоблюдении официально-деловой коммуникации с сотрудниками компании, в случае нарушения прав сотрудников
        Телега в том числе в случае оскорбления сотрудников компании, унижения чести и достоинства сотрудников компании,
        использования ненормативной лексики, угроз и пр.
    </li>
</ol>
</p>
<p>
    11. Телега осуществляет бесплатное хранение экспресс-груза в течение 8 календарных дней (в некоторых случаях срок
    может быть увеличен в зависимости от партнера доставки. Уточнить точную информацию о сроках хранения можно
    обратившись в службу поддержки). По согласованию сторон сроки хранения могу быть продлены. По истечении сроков
    хранения груза Телега вправе по своему усмотрению от своего имени утилизировать и/или реализовать груз. Клиент
    подтверждает, что он уведомлен и согласен с тем, что в случае реализации или утилизации экспресс-груза, стоимость
    экспресс-груза, а также стоимость доставки Клиенту не возмещается.
</p>
<p>
    12. Ответственность за недостачу, утрату, повреждение отправлений Телега несет в соответствии с Всемирной почтовой
    конвенцией, утверждаемой Конгрессом Всемирного почтового союза.
</p>
<p>
    13. Возмешение ушерба при потере, недостаче или повреждении : 1. По отправлениям с баркодом RJ - компенсация в
    размере 30 СПЗ (но не более стоимости вложений) и суммы тарифной платы (без платы за заказ) 2. По отправлениям с
    баркодом CJ - компенсация в размере 40 СПЗ и 4,5 СПЗ за каждый килограмм посылки (но не более стоимости вложений)
    плюс сумма тарифной платы. 3. По отправлениям с баркодом EJ - компенсация в размере 130 СПЗ (но не более стоимости
    вложений) плюс сумма тарифной платы.
</p>
<p>
    14. Оплата услуг является подтверждением принятия условий настоящей оферты.
</p>
<p>
    15. При оказании сервисом Телега услуг по выкупу товаров обязательным требованием для Клиента является достоверное
    предоставление всей необходимой информации. Клиент направляет менеджерам сервиса Телега информацию о желаемом
    товаре, его характеристиках, количестве, ссылку на товар в интернет-магазине, контактные данные Клиента и иную
    обязательную информацию. Настоящий сервис создан исключительно для предоставления дополнительных возможностей
    Клиентам при покупке товаров в иностранных интернет-магазинах. Клиент самостоятельно выбирает продавца товара и
    принимает решение. Оплата товара производится Клиентом в адрес сервиса Телега под свою ответственность по
    реквизитам, указанным сервисом. За товар может взиматься комиссия за конвертацию, которая дополнительно
    согласовывается между Клиентом и сервисом. Клиент самостоятельно принимает решение о заказе товара, покупки его
    через сервис и иных условиях сделки. Выкуп товара с помощью услуг сервиса является правом Клиента, а не его
    обязанностью. Клиент ознакомлен и подтверждает, что сервис Телега не является стороной сделки купли-продажи товара
    между Клиентом и интернет-магазином/маркетплейсом В обязанности сервиса Телега входит исключительно передача
    информации о возможном заказе Клиента и оказание услуг по доставке отправлений. Сервис Телега не отвечает за
    качество товара, его характеристики, комплектность или иные вопросы, связанные с товаром, а также за его доставку до
    консолидационного склада сервиса Телега. обязательства сервиса по организации пересылки возникают только после
    получения посылки на складе сервиса в соответствии с условиями данной публичной оферты.
</p>
`;
