import {
    Button, Col, Form, Row, Input,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useOrderPromoCode } from '../hooks/useOrderPromoCode';
import { IOrder } from '../types/order';
import { useFormWatchMode } from '../hooks/useFormWatchMode';

interface IOrderPromoCodeProps {
    order: IOrder;
    onSucceed?: () => void;
}

function OrderPromoCode({ order, onSucceed }: IOrderPromoCodeProps) {
    const { apply, form, loading } = useOrderPromoCode(order, onSucceed);
    const { t } = useTranslation();
    const { watchMode } = useFormWatchMode();

    return (
        <Form form={form} layout="vertical" onFinish={apply}>
            <Row gutter={[30, 30]}>
                <Col xs={24} md={8}>
                    <Form.Item
                        name="code"
                        label={t('promoCode')}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col hidden={watchMode} xs={24}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        {t('apply')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default OrderPromoCode;
