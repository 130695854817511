import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useImperativeHandle, useRef } from 'react';
import { usePurchaseItems } from '../hooks/usePurchaseItems';
import { IPurchase, IPurchaseItem } from '../types/purchase';
import EditIcon from '../assets/icons/edit';
import TrashIcon from '../assets/icons/trash';
import Table from './table';
import PurchaseItemFormModal, { IPurchaseItemFormModalInstance } from './purchase-item-form-modal';

interface IPurchaseItemsTableProps {
    purchase: IPurchase;
    onFormHide: () => void;
}

export interface IPurchaseItemsTableRef {
    update: () => void;
}

function PurchaseItemsTable({ purchase, onFormHide }: IPurchaseItemsTableProps, ref: any) {
    const {
        data, deleteRow, getData, loading,
    } = usePurchaseItems(purchase.id);
    const { t } = useTranslation();
    const itemFormModal = useRef<IPurchaseItemFormModalInstance>();

    const onHide = () => {
        getData();
        onFormHide();
    };

    useImperativeHandle(ref, (): IPurchaseItemsTableRef => ({
        update: getData,
    }));

    const actions = (row: IPurchaseItem) => (
        <Space>
            <Button
                icon={<EditIcon />}
                type="link"
                onClick={() => itemFormModal.current?.open(purchase, row)}
            />
            <Button
                icon={<TrashIcon />}
                type="link"
                onClick={() => deleteRow(row.id)}
            />
        </Space>
    );

    return (
        <>
            <Table
                loading={loading}
                dataSource={data?.list}
                pagination={{
                    current: data?.currentPage,
                    total: data?.total,
                    pageSize: data?.perPage,
                    hideOnSinglePage: true,
                }}
                columns={[{
                    dataIndex: 'name',
                    title: t('goodName'),
                }, {
                    dataIndex: 'weight',
                    title: t('orderPackageWeight'),
                }, {
                    render: actions,
                    width: 100,
                    fixed: 'right',
                }]}
            />
            <PurchaseItemFormModal ref={itemFormModal} onHide={onHide} />
        </>
    );
}

export default React.forwardRef(PurchaseItemsTable);
