/* eslint-disable react/no-danger */
import React, { useImperativeHandle, useState } from 'react';
import {
    Button, Modal, Space, notification,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from 'antd/es/button';

interface ITermsOfServiceProps {
    acceptButtonProps?: ButtonProps;
    defaultOpen?: boolean;
    onAccepted?: () => void;
    onCanceled?: () => void;
}

export interface ITermsOfServiceRef {
    hide: () => void;
    show: () => void;
}

const TermsOfService = React.forwardRef(({
    acceptButtonProps, defaultOpen, onCanceled, onAccepted,
}: ITermsOfServiceProps, ref) => {
    const [open, setOpen] = useState<boolean>(typeof defaultOpen === 'boolean' ? defaultOpen : true);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const hide = () => setOpen(false);

    const onCancel = () => {
        if (typeof onCanceled === 'function') {
            onCanceled();
        } else {
            notification.error({
                message: t('registrationRejected'),
                description: t('registrationRejectedMessage'),
            });
            navigate(-1);
        }
    };

    const onOk = () => {
        if (typeof onAccepted === 'function') {
            onAccepted();
        } else {
            setOpen(false);
        }
    };

    const show = () => setOpen(true);

    useImperativeHandle(ref, () => ({
        hide,
        show,
    }));

    return (
        <Modal
            closable={false}
            open={open}
            okButtonProps={acceptButtonProps}
            title={`${t('termsOfService')} ${t('serviceName')}`}
            footer={(
                <Space className="form-action-buttons" size="large">
                    <Button type="link" onClick={onCancel}>{t('cancel')}</Button>
                    <Button type="primary" onClick={onOk} loading={acceptButtonProps?.loading}>{t('accept')}</Button>
                </Space>
            )}
        >
            <div dangerouslySetInnerHTML={{ __html: t('termsContent') }} />
        </Modal>
    );
});

export default TermsOfService;
