/* eslint-disable react/no-unused-prop-types */
import { useTranslation } from 'react-i18next';
import {
    Button,
    Card, Col, Divider, Form, Input, Row, Select, Space,
} from 'antd';
import React, { useEffect, useImperativeHandle } from 'react';
import { usePurchase } from '../hooks/usePurchase';
import { useWarehouses } from '../hooks/useWarehouses';
import { getRequiredFieldMessage } from '../helpers/form';
import PurchaseItemsForm from './purchase-items-form';

export interface IPurchaseFormProps {
    id?: number | string;
    onBack?: () => void;
    onSaved?: () => void;
    warehouseId?: number;
}

const PurchaseForm = React.forwardRef((props: IPurchaseFormProps, ref: any) => {
    const { id, onBack, warehouseId } = props;
    const { t } = useTranslation();
    const {
        form, formData, formDataLoading, save, saveLoading, itemForms,
    } = usePurchase(props);
    const warehouses = useWarehouses();

    const newRow = id === 'create';

    useEffect(() => {
        if (typeof warehouseId === 'number') {
            form.setFieldValue('warehouse_id', warehouseId);
        }
    }, [warehouseId]);

    useImperativeHandle(ref, () => ({
        clear: () => form.resetFields(),
    }));

    const title = (
        <Space direction="vertical">
            <span>{newRow ? t('purchaseAdding') : t('myPurchases')}</span>
            {!newRow && <span>{`№${id}`}</span>}
        </Space>
    );

    return (
        <Card className="card-header-wo-border" title={title} loading={formDataLoading}>
            <Form form={form} layout="vertical" onFinish={save}>
                <Row gutter={[30, 30]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('warehouse')}
                            name="warehouse_id"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('warehouse')) }]}
                        >
                            <Select
                                options={warehouses.selectOptions}
                                loading={warehouses.loading}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            className="ant-form-item-with-extra"
                            label={t('goodName')}
                            name="name"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('name')) }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('trackingNumber')}
                            name="tracking_code"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('trackingNumber')) }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={t('shopName')}
                            name="shop_name"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('shopName')) }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    {!newRow && formData && (
                        <Col xs={24} hidden>
                            <Divider style={{ marginTop: 0 }} />
                            <PurchaseItemsForm ref={itemForms} items={formData.items} />
                        </Col>
                    )}
                    <Col className="text-center" xs={24}>
                        <Space className="form-action-buttons" size="large">
                            <Button type="link" onClick={onBack}>
                                {t('back')}
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={saveLoading}
                            >
                                {t('save')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
});

export default PurchaseForm;
