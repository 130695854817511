import { useNavigate, useParams } from 'react-router-dom';
import PurchaseForm from '../../components/purchase-form';

function PurchaseFormPage() {
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <div className="container">
            <PurchaseForm
                id={id}
                onBack={() => navigate(-1)}
                onSaved={() => navigate('/purchases', { replace: true })}
            />
        </div>
    );
}

export default PurchaseFormPage;
