import {
    Button, Checkbox, Col, Form, Input, notification, Row, Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import AuthorizationContent from '../components/authorization-content';
import PhoneInput from '../components/phone-input';
import { getEnteredIncorrectlyFieldMessage, getRequiredFieldMessage } from '../helpers/form';
import { getLanguageForApi } from '../helpers/language';
import { useApp } from '../hooks/useApp';
import { useFetch } from '../hooks/useFetch';
import { useForm } from '../hooks/useForm';
import FormItemWrapper from '../containers/form-item-wrapper';

interface IRegisterResponse {
    token: string;
    emailUniqueError?: boolean;
    phoneUniqueError?: boolean;
}

function RegisterPage() {
    const app = useApp();
    const fetch = useFetch({ showErrorMessage: false });
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [form] = useForm();

    const checkBoxValidator = (_: RuleObject, value: StoreValue, callback: (error?: string) => void) => {
        if (!value) {
            return callback(getRequiredFieldMessage());
        }
        return callback();
    };

    const onPasswordForgotClick = () => {
        notification.close('register-error');
        navigate('/restore-password');
    };

    const onFinish = async (data: Record<string, any>) => {
        const result = await fetch.request<IRegisterResponse>({
            url: '/register',
            data: {
                ...data,
                language: getLanguageForApi(i18n.language),
            },
            method: 'post',
        });
        if (result.success && result.data) {
            app.authSucceed(result.data.token);
        } else {
            notification.error({
                message: result.message,
                btn: result.data?.emailUniqueError || result.data?.phoneUniqueError ? (
                    <Button type="primary" onClick={onPasswordForgotClick}>{t('passwordForgot')}</Button>
                ) : undefined,
                key: 'register-error',
            });
        }
    };

    const onLoginClick = () => navigate('/login');

    return (
        <AuthorizationContent title={t('registration')}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={[30, 30]}>
                    <Col xs={24}>
                        <Form.Item
                            label={t('name')}
                            name="name"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('name')) }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t('surname')}
                            name="surname"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('surname')) }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t('phone')}
                            name="phone"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('phone')) }]}
                        >
                            <PhoneInput />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t('email')}
                            name="email"
                            rules={[
                                { required: true, message: getRequiredFieldMessage(t('email')) },
                                { type: 'email', message: getEnteredIncorrectlyFieldMessage(t('email')) },
                            ]}
                        >
                            <Input type="email" />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t('password')}
                            name="password"
                            rules={[{ required: true, message: getRequiredFieldMessage(t('password')) }]}
                        >
                            <FormItemWrapper component={Input.Password} />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label={t('confirmPassword')}
                            name="confirmPassword"
                            rules={[
                                { required: true, message: getRequiredFieldMessage(t('confirmPassword')) },
                                ({ getFieldValue }) => ({
                                    validator: (_, value) => {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(t('passwordsDoNotMatch') as string));
                                    },
                                }),
                            ]}
                        >
                            <FormItemWrapper component={Input.Password} />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Space direction="vertical">
                            <Form.Item
                                className="checkbox-form-item"
                                rules={[{ validator: checkBoxValidator }]}
                                name="personal-data"
                                valuePropName="checked"
                            >
                                <Space>
                                    <Checkbox />
                                    <Button
                                        className="height-auto"
                                        type="link"
                                        target="_blank"
                                        href="https://telega.pro/personal-data"
                                    >
                                        {t('acceptPersonalData')}
                                    </Button>
                                </Space>
                            </Form.Item>
                            <Form.Item
                                className="checkbox-form-item"
                                rules={[{ validator: checkBoxValidator }]}
                                name="terms-of-service"
                                valuePropName="checked"
                            >
                                <Space>
                                    <Checkbox />
                                    <Button
                                        className="height-auto"
                                        type="link"
                                        target="_blank"
                                        href="https://telega.pro/public-offer"
                                    >
                                        {t('acceptTermsOfUse')}
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Space>
                    </Col>
                    <Col xs={24}>
                        <Space className="w-100" direction="vertical" size="large">
                            <Button
                                className="w-100 ant-btn-dark"
                                type="default"
                                htmlType="submit"
                                size="large"
                                loading={fetch.loading}
                            >
                                {t('register')}
                            </Button>
                            <Button type="link" onClick={onLoginClick}>
                                {t('authorization')}
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </AuthorizationContent>
    );
}

export default RegisterPage;
