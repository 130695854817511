import { useEffect, useState } from 'react';
import { DefaultOptionType } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';
import { useFetch } from './useFetch';

export const useParcelTypesForChina = () => {
    const fetch = useFetch();
    const [data, setData] = useState<DefaultOptionType[]>([]);
    const { i18n } = useTranslation();

    const getData = async () => {
        const result = await fetch.request<DefaultOptionType[]>({
            url: '/orders/parcel-types/china',
        });
        if (result.success && Array.isArray(result.data)) {
            setData(result.data);
        }
    };

    useEffect(() => {
        getData();
    }, [i18n.language]);

    return {
        data,
        loading: fetch.loading,
    };
};
