function TrashIcon() {
    return (
        <span className="anticon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 26" fill="none">
                <path d="M7.58203 22.75C6.9862 22.75 6.47595 22.5377 6.05128 22.113C5.62661 21.6883 5.41464 21.1784 5.41536 20.5833V6.5H4.33203V4.33333H9.7487V3.25H16.2487V4.33333H21.6654V6.5H20.582V20.5833C20.582 21.1792 20.3697 21.6894 19.945 22.1141C19.5204 22.5388 19.0105 22.7507 18.4154 22.75H7.58203ZM9.7487 18.4167H11.9154V8.66667H9.7487V18.4167ZM14.082 18.4167H16.2487V8.66667H14.082V18.4167Z" fill="#DB8912" />
            </svg>
        </span>
    );
}

export default TrashIcon;
