import { Card, List, Typography } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../hooks/useFetch';
import { IListResponse } from '../../types/list';
import { ITransaction } from '../../types/transaction';

function HomeTransactionsPanel() {
    const [list, setList] = useState<ITransaction[]>([]);
    const listFetch = useFetch();
    const { i18n, t } = useTranslation();

    const getList = async () => {
        const result = await listFetch.request<IListResponse<ITransaction>>({
            url: '/transactions',
            method: 'post',
            data: {
                count: 1000,
            },
        });
        if (result.success && result.data) {
            setList(result.data.list);
        }
    };

    useEffect(() => {
        getList();
    }, [i18n.language]);

    const renderItems = () => list.map((item) => (
        <List.Item key={item.id}>
            <List.Item.Meta
                description={moment(item.created_at).format('DD.MM.YYYY HH:m')}
                title={`${item.value}${item.currencySuffix} ${item.typeLabel} №${item.order_id}`}
            />
        </List.Item>
    ));

    return (
        <Card bordered={false} title={t('transactions')} loading={listFetch.loading}>
            {list.length > 0 && (
                <List>
                    {renderItems()}
                </List>
            )}
            {list.length === 0 && (
                <Typography.Text>
                    {t('transactionsEmpty')}
                </Typography.Text>
            )}
        </Card>
    );
}

export default HomeTransactionsPanel;
