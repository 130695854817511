function ExitIcon({
    className, width, height, style,
}: any) {
    return (
        <span className={`anticon ${className || ''}`} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width={width || '20'} height={height || '20'} viewBox="0 0 24 24">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.707 2.40894C9 3.03594 9 4.18294 9 6.47594V17.5239C9 19.8169 9 20.9639 9.707 21.5909C10.414 22.2179 11.495 22.0299 13.657 21.6529L15.987 21.2469C18.381 20.8289 19.578 20.6199 20.289 19.7419C21 18.8629 21 17.5929 21 15.0519V8.94794C21 6.40794 21 5.13794 20.29 4.25894C19.578 3.38094 18.38 3.17194 15.986 2.75494L13.658 2.34794C11.496 1.97094 10.415 1.78294 9.708 2.40994L9.707 2.40894ZM12 10.1689C12.414 10.1689 12.75 10.5199 12.75 10.9529V13.0469C12.75 13.4799 12.414 13.8309 12 13.8309C11.586 13.8309 11.25 13.4799 11.25 13.0469V10.9529C11.25 10.5199 11.586 10.1689 12 10.1689Z" fill="#4B4B4B" />
                <path d="M7.547 4.5C5.489 4.503 4.416 4.548 3.732 5.232C3 5.964 3 7.142 3 9.5V14.5C3 16.857 3 18.035 3.732 18.768C4.416 19.451 5.489 19.497 7.547 19.5C7.5 18.876 7.5 18.156 7.5 17.377V6.623C7.5 5.843 7.5 5.123 7.547 4.5Z" fill="#4B4B4B" />
            </svg>
        </span>
    );
}

export default ExitIcon;
